import { useEffect, useContext, useReducer } from 'react';
import { getProducts, searchProducts } from '../APIs/ProductAPIs.js';
import ToastContext from '../Context/ToastContext';

const initialState = {
  data: [],
  searchData: [],
  maxCount: 0,
  loading: false,
  type: 'Our store',
  input: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.payload };
    case 'SET_SEARCH_DATA':
      return { ...state, searchData: action.payload };
    case 'SET_MAX_COUNT':
      return { ...state, maxCount: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_TYPE':
      return { ...state, type: action.payload };
    case 'SET_INPUT':
      return { ...state, input: action.payload };
    default:
      throw new Error();
  }
}

const useStoreProducts = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addToasts } = useContext(ToastContext);

  const searchProductsByType = async (input, skip, type) => {
    const isUsed = type !== 'Our store';
    const searchQuery = { text: input, skip, isUsed };
    const product = await searchProducts(searchQuery);
    const { products, numberOfProducts } = product.data.data;
    dispatch({ type: 'SET_MAX_COUNT', payload: numberOfProducts });
    return products;
  };

  const handleSearch = async () => {
    if (state.input) {
      try {
        const products = await searchProductsByType(state.input, 0, state.type);
        dispatch({ type: 'SET_SEARCH_DATA', payload: products });
      } catch (err) {
        addToasts({
          type: 'danger',
          heading: 'Internal server error',
          body: 'Unexpected error',
        });
      }
    } else {
      dispatch({ type: 'SET_SEARCH_DATA', payload: [] });
      fetchMoreData();
    }
  };

  const handleMoreSearch = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const products = await searchProductsByType(state.input, state.searchData.length, state.type);
      dispatch({ type: 'SET_SEARCH_DATA', payload: [...state.searchData, ...products] });
      dispatch({ type: 'SET_LOADING', payload: false });
    } catch (err) {
      addToasts({
        type: 'danger',
        heading: 'Internal server error',
        body: 'Unexpected error',
      });
    }
  };

  const fetchMoreData = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const searchQuery = {
        skip: state.data.length || 0,
        isUsed: state.type !== 'Our store' || false,
      };

      if (state.data.length && state.data[0].isUsed !== searchQuery.isUsed) {
        searchQuery.skip = 0;
      }
      const product = await getProducts(searchQuery);
      console.log(product);
      const { products, numberOfProducts } = product.data.data;
      dispatch({ type: 'SET_MAX_COUNT', payload: numberOfProducts });
      dispatch({ type: 'SET_DATA', payload: searchQuery.skip === 0 ? [...products] : [...state.data, ...products] });
      dispatch({ type: 'SET_LOADING', payload: false });
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [state.input, state.type]);

  useEffect(() => {
    fetchMoreData();
  }, [state.type]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight ||
        state.loading ||
        state.data.length >= state.maxCount
      )
        return;
      !state.input ? fetchMoreData() : handleMoreSearch();
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [state.loading, state.data, state.maxCount]);

  const handleInputChange = e => {
    dispatch({ type: 'SET_INPUT', payload: e.target.value });
  };

  const setType = type => {
    dispatch({ type: 'SET_TYPE', payload: type });
  };

  return {
    data: state.data,
    searchData: state.searchData,
    maxCount: state.maxCount,
    loading: state.loading,
    type: state.type,
    input: state.input,
    handleSearch,
    handleMoreSearch,
    fetchMoreData,
    handleInputChange,
    setType,
  };
};

export default useStoreProducts;
