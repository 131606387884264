import '../Styles/OrderDetails.css';
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getProductOrderById, updateProductOrder } from '../APIs/OrderAPIs';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import convertDateToDateString from '../Utils/convertDateToDateString';
import Layout from '../Components/_common/Layout';
import FullNameConcat from '../Utils/FullNameConcat';

export default function OrderDetails() {
  const {
    state: { id },
  } = useLocation();
  const { addToasts } = useContext(ToastContext);

  const [order, setOrder] = useState({});
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const isValid = type !== order.status;

  const getOrderData = async () => {
    try {
      setLoading(true);
      let { data } = await getProductOrderById(id);
      console.log(data.data.order);
      setOrder(data.data.order);
      setType(data.data.order.status);

      setLoading(false);
    } catch (err) {
      console.error(err);
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };
  useEffect(() => {
    getOrderData();
  }, []);

  const onClick = async e => {
    e.preventDefault();
    try {
      await updateProductOrder(id, { status: type });
      getOrderData();
      addToasts({
        type: 'success',
        body: 'Order updated successfully',
      });
    } catch (err) {
      console.error(err);
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  return (
    <Layout title={'Order Details'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="grid gap-5 md:grid-cols-3 my-5">
          <div className="bg-white p-5 rounded-xl">
            <h2 className="text-2xl font-semibold">About user</h2>
            <div className=" flex flex-col">
              <span className="text-gray-500">From</span>
              <div className="flex items-center my-3">
                <img
                  src={order.user?.avatar}
                  width={40}
                  height={40}
                  crossOrigin="anonymous"
                  className="w-12 h-12 rounded-full"
                  alt={order.user?.username}
                ></img>
                <h2 className="ml-5">{order.user && FullNameConcat(order?.user)}</h2>
              </div>
              <span className="text-lg text-gray-500">Address</span>
              <span className="text-xl capitalize">{order.deliveryAddress?.address}</span>
              <span className="text-lg text-gray-500">Building number</span>
              <span className="text-xl capitalize">{order.deliveryAddress?.building}</span>
              <span className="text-lg text-gray-500">Flat</span>
              <span className="text-xl capitalize">{order.deliveryAddress?.flat}</span>
              <span className="text-lg text-gray-500">Phone number</span>
              <span className="text-xl capitalize">{order.user?.phone_number}</span>
              <span className="text-lg text-gray-500">Total</span>
              <span className="text-xl capitalize">{order.total_price}</span>
              <span className="text-lg text-gray-500">{convertDateToDateString(order.createdAt)}</span>
            </div>
          </div>
          <div className="bg-white p-5 rounded-xl">
            <h2 className="text-2xl font-semibold">Order</h2>
            <div>
              {order.products?.map(item => (
                <div key={item.id} className="orderBox">
                  <img
                    crossOrigin="anonymous"
                    src={item.product.images[0]}
                    width={80}
                    height={80}
                    alt={item.product.title}
                  ></img>
                  <div className="sc3boxleft">
                    <div>{item.product.title}</div>
                    <div className="storerub">{item.price} EGP</div>
                    <hr></hr>
                    <div className="underline">
                      <div>
                        {item.price} X {item.quantity}
                      </div>
                      <div>{item.price * item.quantity} EGP</div>
                    </div>
                  </div>
                </div>
              ))}
              <span className="mt-5 text-xl text-gray-500 inline-block">Total</span>
              <p className="text-lg capitalize">{order.total_price} EGP</p>
              <span className="text-lg text-gray-500">{convertDateToDateString(order.createdAt)}</span>
            </div>
          </div>
          <div className="p-5 bg-white rounded-xl h-fit">
            {order.status !== 'DELIVERED' ? (
              <>
                <h2 className="text-2xl font-semibold">Order tracking</h2>
                <div className="space-x-2 my-3 flex flex-wrap">
                  <span
                    className={`${
                      type === 'PENDING' ? 'bg-[#116acc]' : 'bg-gray-400'
                    } cursor-pointer p-2 text-lg rounded-xl text-white flex-shrink-0`}
                    onClick={() => setType('PENDING')}
                  >
                    Prepare to deliver
                  </span>
                  <span
                    className={`${
                      type === 'DELIVERING' ? 'bg-[#116acc]' : 'bg-gray-400'
                    } cursor-pointer p-2 text-lg rounded-xl  text-white flex-shrink-0`}
                    onClick={() => setType('DELIVERING')}
                  >
                    Delivering
                  </span>
                  <span
                    className={`${
                      type === 'DELIVERED' ? 'bg-[#116acc]' : 'bg-gray-400'
                    } cursor-pointer p-2 text-lg rounded-xl text-white flex-shrink-0`}
                    onClick={() => setType('DELIVERED')}
                  >
                    Delivered
                  </span>
                </div>
                <button
                  disabled={!isValid}
                  onClick={onClick}
                  className={`${
                    !isValid ? 'bg-[#9ca3af]' : 'bg-[#116acc]'
                  } cursor-pointer rounded-xl text-xl mx-auto w-1/2 block text-center mt-5 p-3 text-white`}
                >
                  Update
                </button>
              </>
            ) : (
              <div className="p-5 bg-white rounded-xl">
                <h2 className="text-2xl font-semibold">Track order</h2>
                <span className="text-center block font-semibold text-xl my-2 text-gray-500">Order was done</span>
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}
