import React from 'react';
import AsyncSelect from 'react-select/async';
import { Modal } from 'flowbite-react';
import useEditModal from '../../Hooks/Hub/useEditModal';
function EditModal({ openModal, setOpenModal, handleUpdateSuccess }) {
  const {
    productDefaultOptions,
    mealDefaultOptions,
    courseDefaultOptions,
    productSearchOptions,
    mealSearchOptions,
    courseSearchOptions,
    handleSubmit,
    handleSelectItem,
  } = useEditModal({ setOpenModal, handleUpdateSuccess });

  return (
    <Modal
      dismissible
      show={openModal}
      size={'4xl'}
      onClose={() => setOpenModal(false)}
      theme={{
        content: {
          base: 'bg-white rounded-lg shadow-lg p-6 relative h-full w-full max-w-4xl p-4 md:h-auto',
          inner: 'bg-white ',
        },
        root: {
          base: 'fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full',
          show: {
            on: 'flex bg-gray-900 bg-opacity-70',
            off: 'hidden',
          },
        },
        header: {
          base: 'flex items-start justify-between rounded-t border-b p-5',
          popup: 'border-b-0 p-2',
          title: 'text-xl font-medium text-gray-900',
          close: {
            base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900',
            icon: 'h-5 w-5',
          },
        },
        footer: {
          base: 'flex items-center space-x-2 rounded-b border-gray-200 p-6 ',
          popup: 'border-t',
        },
      }}
    >
      <Modal.Header>Edit Recommendations</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <h1 className="text-2xl font-semibold text-gray-800">Select Products</h1>
          <AsyncSelect
            name="products"
            isMulti
            cacheOptions
            defaultOptions={productDefaultOptions}
            loadOptions={productSearchOptions}
            onChange={selectedProducts => handleSelectItem(selectedProducts, 'PRODUCT')}
            formatOptionLabel={option => (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={option.image}
                    alt={option.label}
                    className="w-12 h-12 rounded-full"
                    crossOrigin="anonymous"
                  />
                  <span className="ml-3">{option.label}</span>
                </div>
                <span className="text-gray-500">{option.price}</span>
              </div>
            )}
          />
          <h1 className="text-2xl font-semibold text-gray-800">Select Meals</h1>
          <AsyncSelect
            name="meals"
            isMulti
            cacheOptions
            defaultOptions={mealDefaultOptions}
            loadOptions={mealSearchOptions}
            onChange={selectedMeals => handleSelectItem(selectedMeals, 'MEAL')}
            formatOptionLabel={option => (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={option.image}
                    alt={option.label}
                    className="w-12 h-12 rounded-full"
                    crossOrigin="anonymous"
                  />
                  <span className="mx-3">{option.label}</span>
                  {option.type === 'DINNER' ? (
                    <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                      Dinner
                    </span>
                  ) : option.type === 'BREAKFAST' ? (
                    <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                      Breakfast
                    </span>
                  ) : (
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                      Lunch
                    </span>
                  )}
                </div>
                <span className="text-gray-500">{option.price}</span>
              </div>
            )}
          />
          <h1 className="text-2xl font-semibold text-gray-800">Select Courses</h1>
          <AsyncSelect
            name="courses"
            isMulti
            cacheOptions
            defaultOptions={courseDefaultOptions}
            loadOptions={courseSearchOptions}
            onChange={selectedCourses => handleSelectItem(selectedCourses, 'COURSE')}
            formatOptionLabel={option => (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={option.image}
                    alt={option.label}
                    className="w-12 h-12 rounded-full"
                    crossOrigin="anonymous"
                  />
                  <span className="ml-3">{option.label}</span>
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium ml-2 px-2.5 py-0.5 rounded">
                    {option.category}
                  </span>
                </div>
                <span className="text-gray-500">
                  {option.is_free
                    ? 'Free'
                    : option.discount
                    ? `${option.price - (option.price * option.discount) / 100} EGP`
                    : option.price + ' EGP'}
                </span>
              </div>
            )}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="
          bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
        "
          onClick={handleSubmit}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
