import { useEffect, useState, useContext } from 'react';
import { getHubRecommendation } from '../../APIs/HubAPIs';
import ToastContext from '../../Context/ToastContext';

export default function useHub() {
  const { addToasts } = useContext(ToastContext);
  const [recommendedItems, setRecommendedItems] = useState({
    MEAL: [],
    PRODUCT: [],
    COURSE: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchHub = async () => {
    setLoading(true);
    try {
      const { data } = await getHubRecommendation();
      setRecommendedItems(data.data);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHub();
  }, []);

  return {
    meals: recommendedItems.MEAL?.filter(item => item != null).length > 0 ? recommendedItems.MEAL : null,
    courses: recommendedItems.COURSE?.filter(item => item != null).length > 0 ? recommendedItems.COURSE : null,
    products: recommendedItems.PRODUCT?.filter(item => item != null).length > 0 ? recommendedItems.PRODUCT : null,
    loading,
    fetchHub,
  };
}
