import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { addEvent } from '../APIs/EventAPIs';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import uploadIcon from '../Assets/plus.png';
import '../Styles/EditEvent.css';
import ToastContext from '../Context/ToastContext';
import DatePicker from 'react-multi-date-picker';
import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';

export default function AddEvent() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]); // Store multiple files
  const [fileNames, setFileNames] = useState('No selected files');
  const fileInputRef = useRef(null);
  const [type, setType] = useState('event');
  const [title, setTitle] = useState('');
  const [active, setActive] = useState(true);
  const [date, setDate] = useState(new Date());
  const [details, setDetails] = useState('');
  const [location, setLocation] = useState('');
  const { addToasts } = useContext(ToastContext);
  const isValid = type.trim() && files.length > 0 && title.trim() && details.trim() && location.trim();

  const handleIconClick = event => {
    fileInputRef.current.click();
  };
  
  const handleActiveChange = event => {
    setActive(event.target.checked);
  };

  const handleFileChange = event => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    const fileNames = selectedFiles.map(file => file.name).join(', ');
    setFileNames(fileNames);
  };

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const onClick = async event => {
    setLoading(true);
    event.preventDefault();
    try {
      if (!files.length) {
        addToasts({
          body: 'Please select files',
          type: 'danger',
        });
        return;
      }
      if (files.length > 10) {
        addToasts({
          body: 'too many images , max num is 10',
          type: 'danger',
        });
        return;
      }
      const formData = new FormData();
      files.forEach(file => formData.append('files', file));
      const res = await upload(formData);
      const { fileLinks } = res.data;
      if (!fileLinks) {
        addToasts({
          body: 'Error uploading the files',
          type: 'danger',
        });
        return;
      }
      await addEvent({
        title,
        details,
        photo: fileLinks,
        location,
        date,
        type,
        active,
      });
      addToasts({
        body: 'event added successfully',
        type: 'success',
      });
      navigate(-1);

      setLoading(false);
    } catch (err) {
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Layout title={'Add Event'}>
          <div className="w-full md:w-1/2 my-6 mx-auto bg-white rounded-2xl p-5 flex flex-col space-y-3">
            <label>Type of event</label>
            <div className="typeOfEvent">
              <label className={type === 'event' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="options"
                  value="event"
                  checked={type === 'event'}
                  onChange={handleTypeChange}
                />
                Event
              </label>
              <label className={type === 'conference' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="options"
                  value="conference"
                  checked={type === 'conference'}
                  onChange={handleTypeChange}
                />
                Conference
              </label>
              <label className={type === 'party' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="options"
                  value="party"
                  checked={type === 'party'}
                  onChange={handleTypeChange}
                />
                Party
              </label>
            </div>
            <label>Title of Event</label>
            <input
              id="eventTitle"
              placeholder="Title of Event"
              value={title}
              onChange={e => setTitle(e.target.value)}
            ></input>
            <label>Details </label>
            <textarea
              id="eventDetails"
              placeholder="Details"
              value={details}
              onChange={e => setDetails(e.target.value)}
            ></textarea>
            <label>Event photo</label>
            <div className="photoUpload">
              <div className="uploadCustom" placeholder="hello">
                <span style={{ color: '#116ACC' }}>{fileNames}</span>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={fileInputRef}
                  placeholder="Photo"
                  onChange={handleFileChange}
                  multiple
                ></input>
              </div>
              <img src={uploadIcon} alt="event" onClick={handleIconClick}></img>
            </div>
            <label>Event location</label>
            <input
              id="eventTitle"
              placeholder="Location of Event"
              value={location}
              onChange={e => setLocation(e.target.value)}
            ></input>
            <div>
              <label htmlFor="active">Active</label>
              <input className="ml-2" id="active" type="checkbox" checked={active} onChange={handleActiveChange} />
            </div>
            <label>Select date</label>
            <DatePicker onChange={setDate} value={date} />

            <button
              className="disabled:bg-gray-400 w-1/3 bg-blue-500 p-2 my-2 text-xl mx-auto rounded-md  cursor-pointer text-white"
              disabled={!isValid}
              onClick={onClick}
            >
              Add
            </button>
          </div>
        </Layout>
      )}{' '}
    </>
  );
}
