import edit4 from '../Assets/Frame 48095608 (1).png';
import '../Styles/Universities.css';
import '../Styles/Jobs.css';
import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';
import Pagination from '../Components/_common/Pagination';
import JobCard from '../Components/Jobs/JobCard';
import useJobs from '../Hooks/useJobs';

export default function Jobs() {
  const { loading, input, data, page, totalPages, handleSelectJob, handlePageChange, handleInputChange, navigate } =
    useJobs();

  return (
    <Layout
      title={'Jobs'}
      Action={
        <>
          <input
            className="w-1/2 border p-2 rounded-lg mx-5"
            placeholder="Search"
            value={input}
            onChange={handleInputChange}
          ></input>
          <img
            src={edit4}
            alt="text"
            width={40}
            height={40}
            onClick={() => navigate('/jobs/addjob')}
            style={{ cursor: 'pointer' }}
          ></img>
        </>
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : !data.length ? (
        <h2 className="text-2xl my-10 w-full text-center">No Jobs Found</h2>
      ) : (
        <>
          <div className="grid md:grid-cols-3 gap-6">
            {data?.map((job, index) => (
              <JobCard key={index} job={job} handleSelectJob={handleSelectJob} />
            ))}
          </div>
          <Pagination page={page} totalPages={totalPages} handlePageChange={handlePageChange} />
        </>
      )}
    </Layout>
  );
}
