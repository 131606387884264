import Sidebar from '../Components/Sidebar';
import cursor from '../Assets/arrowframe.png';
import edit from '../Assets/edit.png';
import '../Styles/Course.css';
import vector1 from '../Assets/vector (1).png';
import dollar from '../Assets/u_dollar-sign-alt.png';
import clock from '../Assets/u_clock-three.png';
import star from '../Assets/vector (2).png';
import friend from '../Assets/vector (3).png';
import LoadingSpinner from '../Components/LoadingSpinner';
import CourseHook from '../Hooks/CourseHook';
import { Accordion, Label } from 'semantic-ui-react';
import convertDateToDateString from '../Utils/convertDateToDateString';
import blankDefaultImage from '../Assets/blankDefaultImage.png';
import { MdOutlineDiscount } from 'react-icons/md';

export default function Course() {
  const [data, courceContent, loading, navigate] = CourseHook();
  return (
    <div>
      <div className="theCourse">
        <Sidebar></Sidebar>
        <div className="theCourseContent">
          <div className="theCourseFirstCard">
            <div>
              <img
                crossOrigin="anonymous"
                onClick={() => navigate(-1)}
                style={{ cursor: 'pointer' }}
                src={cursor}
                alt="back icon"
              ></img>
              <div>The course</div>
            </div>
            <div>
              <img
                src={edit}
                alt="edit icon"
                crossOrigin="anonymous"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/courses/course/editcourse', { state: { data } })}
              ></img>
            </div>
          </div>

          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="theCourseContentCards">
              <div className="theCoursesCard p-5">
                <div id={data.id} className="w-full flex items-center justify-between text-lg font-semibold my-2">
                  <span className="text-3xl" id={data.id}>
                    {data.title}
                  </span>

                  <span className={data.active === true ? 'text-green-600' : 'text-red-600'} id={data.id}>
                    {data.active ? 'Active' : 'Not Active'}
                  </span>
                </div>
                <p>
                  {data.discount > 0 && (
                    <span className="coursePrice mr-2">{data.price - (data.price * data.discount) / 100 + ' EGP'}</span>
                  )}
                  <span className={`coursePrice ${data.discount > 0 ? 'line-through text-gray-500' : ''}`}>
                    {data.isFree ? ' Free' : `${data.price} EGP`}
                  </span>
                </p>

                <div className="theCourseProperties">
                  <div className="courseType font-semibold">
                    <span>
                      <img src={dollar} alt="" className="icon"></img>
                      <span> {data.price * data.students_count} EGP</span>
                    </span>
                    <span>
                      <MdOutlineDiscount className="text-3xl text-gray-500" />
                      <span> {data.discount} %</span>
                    </span>
                    {/* // !deprecated */}
                    {/* <span
                      onClick={() =>
                        navigate('/courses/course/viewers', {
                          state: { data: data.viewers },
                        })
                      }
                      style={{ cursor: 'pointer', color: '#116ACC' }}
                    >
                      <img src={friend} alt="" className="icon"></img>
                      <span>{data.viewers.length} Viewers</span>
                    </span> */}
                    <span>
                      <img src={vector1} alt="" className="icon"></img>
                      <span>{data.category}</span>
                    </span>
                    <span>
                      <img src={clock} alt="" className="icon"></img>
                      <span> {data.time} H</span>
                    </span>
                    <span>
                      <img src={star} alt="" className="icon"></img>
                      <span>
                        {data.average_rating}
                        {/* // !deprecated */}
                        {/* ({data.reviews.length}) */}
                      </span>
                    </span>
                    <span
                      // !deprecated
                      // onClick={() =>
                      //   navigate('/courses/course/students', {
                      //     state: { data: data.students },
                      //   })
                      // }
                      style={{ color: '#116ACC' }}
                    >
                      <img src={friend} alt="" className="icon"></img>
                      <span>{data.students_count} Students</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="courseAboutCard p-5">
                <div className="font-bold">About</div>
                <div>{data.about}</div>
              </div>

              <div className="theCourseContentCard p-5">
                <div className="mb-3 font-bold">Week Contents</div>
                {courceContent?.map(w => {
                  return (
                    <details key={w.id} className="p-5 my-5">
                      <summary className="my-3 font-bold text-blue-500">
                        {w.title}
                        <span className="flex justify-end text-blue-400">{convertDateToDateString(w.createdAt)}</span>
                      </summary>
                      <Accordion
                        defaultActiveIndex={[0]}
                        styled
                        fluid
                        exclusive={false}
                        panels={w.content?.map(content => {
                          return {
                            key: content.id,
                            title: {
                              content:
                                content.TYPE === 'ARTICLE' ? (
                                  <>
                                    <Label icon="file text" color="teal" content="ARTICLE" />
                                    <span className="ms-3">{content.title}</span>
                                  </>
                                ) : content.TYPE === 'VIDEO' ? (
                                  <>
                                    <Label icon="video" color="blue" content="VIDEO" />
                                    <span className="ms-3">{content.title}</span>
                                  </>
                                ) : (
                                  content.TYPE === 'EXAM' && (
                                    <>
                                      <Label icon="pencil alternate" color="pink" content="EXAM" />
                                      <span className="ms-3">{content.title}</span>
                                    </>
                                  )
                                ),
                            },
                            content: content.description,
                          };
                        })}
                      />
                    </details>
                  );
                })}
              </div>
              <div className="theCourseReviews">
                <div className="theCourseReviewsNumber">
                  <div className="text-dark_gray p-5 text-bold">Reviews</div>
                </div>
                {/* // ! deprecated */}
                {/* {data.reviews?.map(review => (
                  <div className="review">
                    <div>
                      <img
                        src={review.user.avatar}
                        alt="user avatar"
                        height={40}
                        width={40}
                        style={{ borderRadius: '50%' }}
                      ></img>
                      <div>
                        <div>{review.user.name}</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>{data.avarageRating}</div>
                          {Array.from({ length: 5 }, (_, index) => (
                            <img
                              key={index}
                              src={index < review.rating ? filledStar : emptyStar}
                              alt="rating star"
                              height={25}
                              width={25}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div>{review.reviewText}</div>
                  </div>
                ))} */}
              </div>
              <div className="theCoursePhoto">
                <img crossOrigin="anonymous" src={data.photo || blankDefaultImage} alt="Course"></img>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
