import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { updateJob } from '../APIs/JobAPIs';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import uploadIcon from '../Assets/plus.png';
import Layout from '../Components/_common/Layout';

export default function EditJob() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  const id = data.id;
  const link = data.photo.split('/');
  const [fileName, setFileName] = useState(link[link.length - 1]);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [title, setTitle] = useState(data.title);
  const [active, setActive] = useState(data.active);
  const [about, setAbout] = useState(data.about);
  const [location, setLocation] = useState(data.location);
  const { addToasts } = useContext(ToastContext);
  const isValid = fileName && title.trim() && about.trim() && location.trim();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    setFile(event.target.files[0]);
    const fileSelected = event.target.files[0] && setFileName(event.target.files[0].name);
    if (fileSelected) {
      console.log('File selected:', file.name);
    }
  };

  const handleActiveChange = event => {
    setActive(event.target.checked);
  };

  const onClick = async event => {
    setLoading(true);
    event.preventDefault();
    try {
      let tmp = {
        title,
        about,
        location,
        active,
      };
      if (file) {
        const formData = new FormData();
        formData.append('files', file);
        const res = await upload(formData);
        if (res.data?.fileLinks.length === 0) {
          addToasts({
            body: 'error uploading the file',
            type: 'danger',
          });
        } else {
          tmp.photo = res.data?.fileLinks[0];
        }
      }
      await updateJob(id, tmp);
      addToasts({
        body: 'job updated successfully',
        type: 'success',
      });
      setLoading(false);
      navigate(-1);
    } catch (err) {
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Layout title={'Edit Job'}>
          <div className="flex flex-col md:w-1/2 mx-auto my-2 p-5 gap-2 bg-white rounded-xl">
            <label>Title of Job</label>
            <input
              className="border-2 p-2 rounded-lg"
              placeholder="Title of Event"
              value={title}
              onChange={e => setTitle(e.target.value)}
            ></input>
            <label>Location</label>
            <input
              className="border-2 p-2 rounded-lg"
              placeholder="Location of Event"
              value={location}
              onChange={e => setLocation(e.target.value)}
            ></input>
            <label>About </label>
            <textarea
              className="border-2 p-2 rounded-lg"
              placeholder="Details"
              value={about}
              onChange={e => setAbout(e.target.value)}
            ></textarea>
            <div>
              <label htmlFor="active">Active</label>
              <input className="ml-2" id="active" type="checkbox" checked={active} onChange={handleActiveChange} />
            </div>
            <label>Job photo</label>
            <div className="photoUpload">
              <div className="uploadCustom" placeholder="hello">
                <span style={{ color: '#116ACC' }}>{fileName}</span>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={fileInputRef}
                  className="uploadInput"
                  placeholder="Photo"
                  onChange={handleFileChange}
                ></input>
              </div>
              <img src={uploadIcon} alt="upload" onClick={handleIconClick}></img>
            </div>
            <button
              className="disabled:bg-gray-400 w-1/3 bg-blue-500 p-2 text-xl mx-auto rounded-md  cursor-pointer text-white"
              disabled={!isValid}
              onClick={onClick}
            >
              Edit
            </button>
          </div>
        </Layout>
      )}
    </>
  );
}
