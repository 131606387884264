import React from 'react';
import convertDateToDateString from '../../Utils/convertDateToDateString';

export default function JobCard({ job, handleSelectJob }) {
  return (
    <div
      key={job.id}
      id={job.id}
      onClick={() => {
        handleSelectJob(job.id);
      }}
      style={{ cursor: 'pointer' }}
      className="jobsCard overflow-hidden"
    >
      <img id={job.id} src={job.photo} alt="job" className="w-full" crossOrigin="anonymous"></img>
      <section className="p-3">
        <div className="flex items-center justify-between">
          <h3 className="capitalize text-xl font-bold" id={job.id}>
            {job.title}
          </h3>
          <span
            id={job.id}
            className={`${
              job.active === true ? 'text-green-500 ' : 'text-red-500'
            } text-xl font-semibold px-1 capitalize`}
          >
            {job.active ? 'active' : 'not active'}
          </span>
        </div>
        <p className="capitalize text-lg">{job.about}</p>
        <div>
          <span className="my-3 font-bold text-cyan-700">Posted at : {convertDateToDateString(job.createdAt)}</span>
        </div>
      </section>
    </div>
  );
}
