import React from 'react';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import convertDateToDateString from '../Utils/convertDateToDateString';
import FullNameConcat from '../Utils/FullNameConcat';
import { ReportHooks } from '../Hooks/reportsHook';
import Pagination from '../Components/_common/Pagination';

function Report() {
  const { content, handleContent, reports, page, handlePage, handleIndex, pageIndex, totalPages, handleClick } =
    ReportHooks();

  console.log({ reports, page });
  return (
    <div>
      <Sidebar />
      <TopCard title="Report" />
      <div className="ml-24 mr-9 flex flex-wrap">
        <div className="w-full p-3 m-2 bg-white rounded-2xl">
          <span
            className={`cursor-pointer mx-2 ${content === 'post' ? 'text-blue-800 font-semibold text-xl' : 'text-black font-normal text-lg'
              }`}
            onClick={() => handleContent('post')}
          >
            Posts Report
          </span>
          <span
            className={`cursor-pointer mx-2 ${content === 'comment' ? 'text-blue-800 font-semibold text-xl' : 'text-black font-normal text-lg'
              }`}
            onClick={() => handleContent('comment')}
          >
            Comments Report
          </span>
          <span
            className={`cursor-pointer mx-2 ${content === 'reel' ? 'text-blue-800 font-semibold text-xl' : 'text-black font-normal text-lg'
              }`}
            onClick={() => handleContent('reel')}
          >
            Reels Report
          </span>
          <span
            className={`cursor-pointer mx-2 ${content === 'story' ? 'text-blue-800 font-semibold text-xl' : 'text-black font-normal text-lg'
              }`}
            onClick={() => handleContent('story')}
          >
            Stories Report
          </span>
          <span
            className={`cursor-pointer mx-2 ${content === 'user' ? 'text-blue-800 font-semibold text-xl' : 'text-black font-normal text-lg'
              }`}
            onClick={() => handleContent('user')}
          >
            Users Report
          </span>
        </div>
        {reports && reports.length ? (
          reports?.map((report, ind) => (
            <section
              key={report.id}
              className="text-white w-5/6 m-auto p-4 mt-2 flex flex-col md:flex-row justify-around items-start bg-white shadow-md rounded-lg"
            >
              <article className="min-h-full flex justify-evenly flex-col bg-[#1A4B96] rounded-3xl p-4 w-full md:w-1/3 mb-4 md:mb-0">
                <div className="flex items-center mb-4">
                  <span className="mr-4 text-lg font-semibold">{page * 20 + ind + 1}-</span>
                  <img
                    src={report.reporter.avatar}
                    className="w-12 h-12 rounded-full border border-x-black mr-4"
                    alt={`${FullNameConcat(report.reporter)}'s avatar`}
                  />
                  <div>
                    <h2 className="text-lg font-semibold">{FullNameConcat(report.reporter)}</h2>
                    <time className="text-sm text-white">{convertDateToDateString(report.createdAt)}</time>
                  </div>
                </div>
                <p className="text-base">
                  {(content === 'post' && report.report) ||
                    (content === 'reel' && report.reason) ||
                    (content === 'story' && report.reason) ||
                    (content === 'user' && report.reason)
                  }
                </p>
                <div className="flex justify-between items-center mt-4 px-8">
                  <button
                    onClick={() => handleClick('ignore', report.id)}
                    className="bg-[#4d85ff] text-white rounded-lg px-4 py-2 mr-2"
                  >
                    Ignore Report
                  </button>
                  <button
                    onClick={() => handleClick('delete', report.id)}
                    className="bg-white text-black rounded-lg px-4 py-2 capitalize"
                  >
                    {`Delete ${content}`}
                  </button>
                </div>
              </article>
              <article className="flex flex-col bg-[#1A4B96]  rounded-3xl p-4  w-full md:w-1/3  ">
                <div className="flex items-center mb-4">
                  <img
                    src={
                      (content === 'post' && report.post?.user?.avatar) ||
                      (content === 'reel' && report.reel?.user?.avatar) ||
                      (content === 'story' && report.story?.user?.avatar) ||
                      (content === 'comment' && report.comment?.user?.avatar) ||
                      (content === 'user' && report.reportedUser?.avatar)
                    }
                    className="w-12 h-12 rounded-full border border-x-black mr-4"
                    alt={`${(report.post?.user && FullNameConcat(report.post?.user)) ||
                      (report.reel?.user && FullNameConcat(report.reel?.user)) ||
                      (report.story?.user && FullNameConcat(report.story?.user)) ||
                      (report.comment?.user && FullNameConcat(report.comment?.user)) ||
                      (report.reportedUser && FullNameConcat(report.reportedUser))
                      }
                    // }'s avatar`}
                  />
                  <div>
                    <h3 className="text-lg font-semibold">
                      {(report.post?.user && FullNameConcat(report.post?.user)) ||
                        (report.reel?.user && FullNameConcat(report.reel?.user)) ||
                        (report.story?.user && FullNameConcat(report.story?.user)) ||
                        (report.comment?.user && FullNameConcat(report.comment?.user)) ||
                        (report.reportedUser && FullNameConcat(report.reportedUser))
                      }
                    </h3>
                    {
                      content !== 'user' &&
                      <p className="text-sm text-white">Posted by</p>
                    }

                  </div>
                </div>
                <p className="text-base mb-4">
                  {(content === 'post' && report.post?.text) ||
                    (content === 'reel' && report.reel?.text) ||
                    (content === 'story' && report.story?.text) ||
                    (content === 'comment' && report.comment?.text) ||
                    (content === 'user' && report.reportedUser?.role) ||
                    ''}
                </p>
                {content === 'post' && report.post?.images?.length > 0 && (
                  <div className="flex overflow-x-auto space-x-2 no-scrollbar justify-between">
                    {report.post?.images?.map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={image}
                        className="w-1/2 object-cover rounded-lg mb-2"
                        alt={`${imgIndex + 1} for ${report.post?.user && FullNameConcat(report.post?.user)}`}
                      />
                    ))}
                  </div>
                )}
                {content === 'comment' && report.comment?.images?.length > 0 && (
                  <div className="flex overflow-x-auto space-x-2 no-scrollbar justify-between">
                    {report.comment?.images?.map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={image}
                        className="w-1/2 object-cover rounded-lg mb-2"
                        alt={`${imgIndex + 1} for ${report.comment?.user && FullNameConcat(report.comment?.user)}`}
                      />
                    ))}
                  </div>
                )}
                {content === 'reel' && (
                  <video
                    controls
                    className="h-[150px] w-auto rounded-3xl"
                    crossOrigin="anonymous"
                    src={report.reel?.video_url}
                    width="400"
                  >
                    <source src={report.reel?.video_url} type="video/mp4" />
                    Your browser does not support the video tag or the video is unavailable.
                  </video>
                )}
                {content === 'story' && (
                  <>
                    {report.story?.image_url !== null && (
                      <img
                        src={report.story?.image_url}
                        className="w-28 h-28 object-cover rounded-lg mb-2"
                        alt={`for ${report.story?.user && FullNameConcat(report.story?.user)}`}
                      />
                    )}
                    {report.story?.video_url !== null && (
                      <video
                        className="h-[150px] w-auto rounded-lg"
                        crossOrigin="anonymous"
                        src={report.story?.video_url}
                        width="400"
                        controls
                      >
                        <source src={report.story?.video_url} type="video/mp4" />
                        Your browser does not support the video tag or the video is unavailable.
                      </video>
                    )}
                  </>
                )}

                {/* //TODO : Fix likes and comments counter  */}
                {/* <div className="flex justify-between items-center mt-4 px-8">
                  <p className="text-base text-gray-500">
                    <BiSolidLike className="inline-block" /> {report.post.likes.length}
                  </p>
                  <p className="text-base text-gray-500">
                    <BiSolidComment className="inline-block mr-1" />
                    {report.post.comments.length}
                  </p>
                </div> 
               */}
              </article>
            </section>
          ))
        ) : (
          <p className="w-5/6 m-auto p-4 mt-2 flex flex-col md:flex-row justify-around items-start bg-white shadow-md rounded-lg">
            No reports available
          </p>
        )}
        <Pagination page={page} totalPages={totalPages} handlePageChange={handlePage} />
      </div>
    </div>
  );
}

export default Report;
