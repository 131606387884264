import React from 'react';
import clock from '../../Assets/u_clock-three.png';
import convertDateToDateString from '../../Utils/convertDateToDateString';

export default function EventCard({ event, handleSelectEvent }) {
  return (
    <div
      key={event.id}
      id={event.id}
      onClick={handleSelectEvent}
      style={{ cursor: 'pointer' }}
      className="eventCard overflow-hidden"
    >
      <div className="w-full flex overflow-x-auto space-x-2 no-scrollbar justify-between">
        {event.photo.map((el, index) => {
          return (
            <img
              key={index}
              id={event.id}
              src={el}
              alt="Product"
              className="max-h-64 p-0 m-0"
              crossOrigin="anonymous"
            />
          );
        })}
      </div>
      <section id={event.id} className="p-3 w-full space-y-2">
        <h2 id={event.id} className="capitalize text-2xl font-bold">
          {event.title}
        </h2>
        <h3 id={event.id} className="capitalize text-md">
          {event.location}
        </h3>

        <span id={event.id} className="text-gray-500 my-2">
          {convertDateToDateString(event.date)}
        </span>
        <p id={event.id} className="capitalize text-lg">
          {event.details}
        </p>
        <span className={event.active === true ? 'text-green-600 text-xl' : 'text-red-600 '} id={event.id}>
          {event.active ? 'Active' : 'Not Active'}
        </span>

        <div id={event.id} className="flex items-center">
          <img id={event.id} className="mr-2" src={clock} width={20} alt="icon" height={20}></img>
          <span id={event.id} className="capitalize font-medium">
            {event.interested_count} interested
          </span>
        </div>
      </section>
    </div>
  );
}
