import React from 'react';
import edit from '../Assets/edit.png';
import deleteIcon from '../Assets/vector (5).png';
import '../Styles/Job.css';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';
import convertDateToDateString from '../Utils/convertDateToDateString';
import JobRequest from '../Components/Jobs/JobRequest/JobRequest';
import useJob from '../Hooks/useJob';

export default function Job() {
  const {
    state: { jobId },
  } = useLocation();
  const { loading, data, requests, handleEditJob, handleDeleteJob } = useJob(jobId);

  return (
    <Layout
      title={'Job'}
      Action={
        <>
          <img
            src={edit}
            height={45}
            width={45}
            alt="edit job"
            style={{ cursor: 'pointer' }}
            onClick={handleEditJob}
          ></img>
          <img
            src={deleteIcon}
            height={40}
            width={40}
            alt="delete job"
            className="bg-slate-200 p-2 mx-2 rounded-full"
            style={{ cursor: 'pointer' }}
            onClick={handleDeleteJob}
          ></img>
        </>
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-full mx-auto my-5 bg-white p-5 rounded-lg" key={data.id}>
          <div className="flex flex-col md:flex-row justify-between gap-8">
            <div className="md:w-1/2">
              <div className="flex justify-between items-center">
                <h1 className="capitalize text-2xl font-bold">{data.title}</h1>
                <span className="text-md text-gray-500">{data.location}</span>
              </div>
              <span className="my-3 font-bold text-cyan-700">
                Created Date : {convertDateToDateString(data.createdAt)}
              </span>
              <p
                id={data.id}
                className={`${
                  data.active === true ? 'text-green-500 ' : 'text-red-500'
                } text-xl font-semibold py-1 capitalize`}
              >
                {data.active ? 'active' : 'not active'}
              </p>
              <p className="capitalize text-lg mt-5">{data.about}</p>
            </div>
            <img crossOrigin="anonymous" src={data.photo} alt="Job" className="md:w-1/2" />
          </div>

          <h2 className="text-dark_gray text-xl font-semibold mt-8">Requests ({requests.length})</h2>
          <div className=" grid md:grid-cols-2 gap-5">
            {requests?.map((request, index) => (
              <JobRequest key={index} request={request} />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
}
