import React, { useState } from 'react';
import Layout from '../../Components/_common/Layout';
import useHub from '../../Hooks/Hub/useHub';
import LoadingSpinner from '../../Components/LoadingSpinner';
import MealCard from '../../Components/Pages/MealPage/MealCard/MealCard';
import HubCard from '../../Components/Hub/HubCard';
import StoreProductCard from '../../Components/StoreProductCard';
import CourseCard from '../../Components/Pages/CoursesPage/CourseCard';
import EditButton from '../../Components/Hub/EditButton';
import EditModal from '../../Components/Hub/EditModal';
function Hub() {
  const [showModal, setShowModal] = useState(false);
  const { loading, meals, products, courses, fetchHub } = useHub();
  const handleUpdateSuccess = () => {
    fetchHub();
  };
  const handleSelectMeal = () => {
    console.log('meal selected');
  };

  const handleSelectProduct = () => {
    console.log('product selected');
  };

  const handleSelectCourse = () => {
    console.log('course selected');
  };

  const noContent = (
    <div className="flex justify-center items-center h-full">
      <h1 className="text-2xl">No content available</h1>
    </div>
  );
  const handleButtonClick = () => {
    setShowModal(true);
  };

  return (
    <Layout title="Hub" Action={<EditButton onClick={handleButtonClick} />}>
      {loading && <LoadingSpinner />}
      <main className="flex flex-col max-h-fit w-full">
        <EditModal openModal={showModal} setOpenModal={setShowModal} handleUpdateSuccess={handleUpdateSuccess} />
        <HubCard title="Products">
          {products &&
            products?.map(product => {
              return <StoreProductCard product={product} key={product.id} handleSelectProduct={handleSelectProduct} />;
            })}
          {!products && noContent}
        </HubCard>
        <HubCard title="Meals">
          {meals &&
            meals?.map(meal => {
              return <MealCard meal={meal} handleSelectMeal={handleSelectMeal} key={meal.id} />;
            })}
          {!meals && noContent}
        </HubCard>
        <HubCard title="Courses">
          {courses &&
            courses?.map(course => {
              return <CourseCard course={course} key={course.id} handleSelectCourse={handleSelectCourse} />;
            })}
          {!courses && noContent}
        </HubCard>
      </main>
    </Layout>
  );
}

export default Hub;
