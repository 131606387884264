import '../Styles/AddLoan.css';
import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';
import useLoan from '../Hooks/useLoan';

export default function EditLoan() {
  const {
    id,
    loading,
    amount,
    preAmount,
    trusts,
    accountPeriod,
    about,
    visitors,
    onClick,
    valid,
    handlePreAmountChange,
    handleVisitorsChange,
    handlePeriodChange,
    handleAboutChange,
    handleAmountChange,
    handleTrustsChange,
    handleDelete,
  } = useLoan();

  return (
    <Layout title={'Edit Loan'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="-full md:w-1/2 mx-auto flex flex-col items-start bg-white rounded-2xl space-y-4 my-5 p-8">
          <label>Price of loan</label>
          <input
            className="w-full p-2 border-black border-[1px] rounded-lg"
            value={amount}
            onChange={e => {
              handleAmountChange(e.target.value);
            }}
            type="Number"
            placeholder="Write the loan value"
          ></input>
          <label>About</label>
          <input
            className="w-full p-2 border-black border-[1px] rounded-lg"
            value={about}
            onChange={e => {
              handleAboutChange(e.target.value);
            }}
            placeholder="Write about for the loan"
          ></input>

          <label>How many EGP should the user account have?</label>
          <input
            className="w-full p-2 border-black border-[1px] rounded-lg"
            value={preAmount}
            onChange={e => {
              handlePreAmountChange(e.target.value);
            }}
            type="Number"
            placeholder="EGP"
          ></input>
          <label>How many trusts should the user account have?</label>
          <input
            className="w-full p-2 border-black border-[1px] rounded-lg"
            value={trusts}
            onChange={e => {
              handleTrustsChange(e.target.value);
            }}
            type="Number"
            placeholder="Trusts"
          ></input>
          <label>How many visitors should the user account have?</label>
          <input
            className="w-full p-2 border-black border-[1px] rounded-lg"
            value={visitors}
            onChange={e => {
              handleVisitorsChange(e.target.value);
            }}
            type="Number"
            placeholder="visitors"
          ></input>
          <label>How long should be passed from opening the user account? (in months)</label>
          <input
            className="w-full p-2 border-black border-[1px] rounded-lg"
            value={accountPeriod}
            onChange={e => {
              handlePeriodChange(e.target.value);
            }}
            type="Number"
            placeholder="Account Period"
          ></input>

          <button
            onClick={e => {
              handleDelete(id);
            }}
            className="text-xl text-red-800 font-medium text-center w-full"
          >
            Delete Loan
          </button>
          <button
            onClick={onClick}
            disabled={!valid}
            className="disabled:bg-gray-400 w-full bg-blue-500 p-2  text-xl mx-auto rounded-md  cursor-pointer text-white"
          >
            Update
          </button>
        </div>
      )}
    </Layout>
  );
}
