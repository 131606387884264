import { useContext, useEffect, useReducer } from 'react';
import ToastContext from '../Context/ToastContext';
import { useNavigate } from 'react-router-dom';
import { addLoan, getLoanRequests, getLoans } from '../APIs/LoanAPIs';

const initialState = {
  loanData: [],
  loanRequests: [],
  page: 0,
  content: 1,
  maxCount: 0,
  loading: false,
  amount: '',
  trusts: '',
  accountPeriod: '',
  preAmount: '',
  about: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, loading: true, error: null };
    case 'FETCH_FINISH':
      return { ...state, loading: false, error: null };
    case 'SET_LOAN_DATA':
      const updatedLoanData = [...action.payload.loanData];
      return {
        ...state,
        loading: false,
        loanData: updatedLoanData,
        maxCount: action.payload.maxCount,
        content: Math.max(state.content, updatedLoanData.length),
      };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    case 'SET_LOAN_REQUESTS_DATA':
      return {
        ...state,
        loading: false,
        loanRequests: [...action.payload],
      };
    case 'SET_AMOUNT':
      return { ...state, amount: action.payload };
    case 'SET_TRUSTS':
      return { ...state, trusts: action.payload };
    case 'SET_ACCOUNT_PERIOD':
      return { ...state, accountPeriod: action.payload };
    case 'SET_ABOUT':
      return { ...state, about: action.payload };
    case 'SET_PREAMOUNT':
      return { ...state, preAmount: action.payload };
    case 'SET_VISITORS':
      return { ...state, visitors: action.payload };
    case 'FETCH_FAILURE':
      return { ...state, loading: false };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const useLoans = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addToasts } = useContext(ToastContext);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      dispatch({ type: 'FETCH_INIT' });
      let searchQuery = { skip: state.loanData.length * state.page };
      const { data } = await getLoans(searchQuery);
      dispatch({
        type: 'SET_LOAN_DATA',
        payload: { loanData: [...data.data.loans], maxCount: data.data.numberOfLoans },
      });
    } catch (error) {
      dispatch({ type: 'FETCH_FAILURE' });
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  };

  const fetchRequests = async () => {
    try {
      dispatch({ type: 'FETCH_INIT', payload: true });
      let searchQuery = { skip: state.loanData.length, status: ['pending', 'approved', 'rejected'] };
      const { data } = await getLoanRequests(searchQuery);
      dispatch({ type: 'SET_LOAN_REQUESTS_DATA', payload: [...data.data.loanRequests.slice(0, 6)] });
    } catch (error) {
      dispatch({ type: 'FETCH_FAILURE' });
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  };

  useEffect(() => {
    fetchData();
    fetchRequests();
  }, [state.page]);

  function handlePageChange(page) {
    dispatch({ type: 'SET_PAGE', payload: page });
  }
  function handleAmountChange(data) {
    dispatch({ type: 'SET_AMOUNT', payload: data });
  }
  function handlePreAmountChange(data) {
    dispatch({ type: 'SET_PREAMOUNT', payload: data });
  }
  function handleTrustsChange(data) {
    dispatch({ type: 'SET_TRUSTS', payload: data });
  }
  function handleVisitorsChange(data) {
    dispatch({ type: 'SET_VISITORS', payload: data });
  }
  function handlePeriodChange(data) {
    dispatch({ type: 'SET_ACCOUNT_PERIOD', payload: data });
  }
  function handleAboutChange(data) {
    dispatch({ type: 'SET_ABOUT', payload: data });
  }

  const onClick = async event => {
    event.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    // Front-end validation for fields greater than 1
    if (state.amount < 1 || state.preAmount < 1 || state.trusts < 1 || state.visitors < 1 || state.accountPeriod < 1) {
      addToasts({
        body: 'All numeric values must be greater than 0',
        type: 'danger',
      });
      dispatch({ type: 'FETCH_FAILURE' });
      return;
    }

    try {
      const tmp = {
        amount: state.amount,
        preAmount: state.preAmount,
        trusts_required: state.trusts,
        visitors: state.visitors,
        accountPeriod: state.accountPeriod,
        about: state.about,
      };

      await addLoan(tmp);
      dispatch({ type: 'FETCH_FINISH' });
      addToasts({
        body: 'Event added successfully',
        type: 'success',
      });
      navigate(-1);
    } catch (err) {
      addToasts({
        body: 'Unexpected error',
        type: 'danger',
      });
      dispatch({ type: 'FETCH_ERROR' });
    }
  };

  return {
    page: state.page,
    totalPages: Math.ceil(state.maxCount / state.content),
    handlePageChange,
    loading: state.loading,
    loanData: state.loanData,
    loanRequests: state.loanRequests,
    valid:
      state.amount !== '' &&
      state.preAmount !== '' &&
      state.trusts !== '' &&
      state.accountPeriod !== '' &&
      state.about !== '' &&
      state.visitors !== '',
    amount: state.amount,
    preAmount: state.preAmount,
    trusts: state.trusts,
    accountPeriod: state.accountPeriod,
    about: state.about,
    visitors: state.visitors,
    navigate,
    onClick,
    handlePreAmountChange,
    handleVisitorsChange,
    handlePeriodChange,
    handleAboutChange,
    handleAmountChange,
    handleTrustsChange,
  };
};

export default useLoans;
