import api from './_common/helper';

export async function searchEvents(type, text, page) {
  return api.get(`/event/search?type=${type}&skip=${page * 10}&title=${text}&dashboard=true`);
}

export async function getEvents(type, page) {
  return api.get(`/event?type=${type}&skip=${page * 10}&dashboard=true`);
}

export async function getEvent(data) {
  return api.get(`/event/${data}`);
}

export async function addEvent(data) {
  return api.post(`event`, data);
}

export async function updateEvent(id, data) {
  return api.put(`/event/${id}`, data);
}

export async function deleteEvent(id) {
  return api.delete(`/event/${id}`);
}
