import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/EditCourse.css';
import { upload } from '../APIs/UploadAPIs';
import { addCourse, addWeekContent, addArticle, addVideo, addExam } from '../APIs/CourseAPIs';
import ToastContext from '../Context/ToastContext';
import blankDefaultImage from '../Assets/blankDefaultImage.png';
import config from '../Config/Config';
function useAddCourse({ course, weekContents }) {
  const [sections, setSections] = useState(weekContents);

  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);

  const [filePreview, setFilePreview] = useState(blankDefaultImage);
  const [signaturePreview, setSignaturePreview] = useState(blankDefaultImage);
  const [formInputs, setFormInputs] = useState({
    title: '',
    about: '',
    is_free: true,
    price: 0,
    discount: 0,
    photo: null,
    professor: { label: 'None', value: '' },
    category: { value: 'Engineering', label: 'Engineering' },
    time: 0,
    language: { value: 'English', label: 'English' },
    level: { value: 'Beginner', label: 'Beginner' },
    roles: [],
    signature: null,
    active: true,
  });

  const signatureOnChangeHandler = e => {
    const file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) {
      addToasts({
        type: 'warning',
        body: `Only images are allowed`,
      });
      setFormInputs({ ...formInputs, signature: null });
      setSignaturePreview(blankDefaultImage);
      return;
    }
    if (file.size > config.MAX_IMAGE_SIZE) {
      addToasts({
        type: 'warning',
        body: `File size should be less than 2MB`,
      });
      setFormInputs({ ...formInputs, signature: null });
      setSignaturePreview(blankDefaultImage);
      return;
    }

    setFormInputs({ ...formInputs, signature: file });
    setSignaturePreview(URL.createObjectURL(file));
  };

  const inputsOnChangeHandler = e => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  const handleActiveChange = event => {
    setFormInputs({ ...formInputs, active: event.target.checked });
  };

  const paymentTypeOnChangeHandler = e => {
    const { value } = e.target;
    if (value === 'free') {
      setFormInputs({ ...formInputs, is_free: true, price: 0, discount: 0 });
      return;
    }
    setFormInputs({ ...formInputs, is_free: false });
  };

  const fileOnChangeHandler = e => {
    const file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) {
      addToasts({
        type: 'warning',
        body: `Only images are allowed`,
      });
      setFormInputs({ ...formInputs, photo: null });
      setFilePreview(blankDefaultImage);
      return;
    }
    if (file.size > config.MAX_IMAGE_SIZE) {
      addToasts({
        type: 'warning',
        body: `File size should be less than 2MB`,
      });
      setFormInputs({ ...formInputs, photo: null });
      setFilePreview(blankDefaultImage);
      return;
    }

    setFormInputs({ ...formInputs, photo: file });
    setFilePreview(URL.createObjectURL(file));
  };

  const professorOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, professor: selectedOption });
  };

  const categoryOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, category: selectedOption });
  };

  const languageOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, language: selectedOption });
  };

  const levelOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, level: selectedOption });
  };

  const roleOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, roles: selectedOption });
  };

  const onClick = async e => {
    e.preventDefault();
    const { title, about, photo, professor, category, time, language, level, roles, discount, signature, active } =
      formInputs;
    console.log(formInputs);
    if (
      !title ||
      !about ||
      !photo ||
      !professor?.value ||
      !category?.value ||
      !time ||
      !language?.value ||
      !level?.value ||
      !roles?.length ||
      !signature ||
      !active
    ) {
      addToasts({
        type: 'warning',
        body: `Please fill all fields`,
      });
      return;
    }
    if (discount > 100) {
      addToasts({
        type: 'warning',
        body: `Discount cannot be more than 100%`,
      });
      return;
    }
    try {
      formInputs.professor_id = formInputs.professor.value;
      formInputs.professor = undefined;
      formInputs.roles = formInputs.roles?.map(r => r.value);
      formInputs.category = formInputs.category.value;
      formInputs.language = formInputs.language.value;
      formInputs.level = formInputs.level.value;
      let formData = new FormData();
      formData.append('files', formInputs.photo);
      formData.append('files', formInputs.signature);
      let res = await upload(formData);
      if (res.status === 200) {
        const { fileLinks } = await res.data;
        if (fileLinks) {
          formInputs.photo = fileLinks[0];
          formInputs.signature = fileLinks[1];
        }
      }
      res = await addCourse(formInputs);
      let { course } = await res.data.data;
      if (sections && sections.length > 0) {
        for (let week of sections) {
          res = await addWeekContent({
            title: week.title,
            course_id: course.id,
          });
          let { content } = await res.data.data;
          for (let l of week.content) {
            if (l.link) {
              res = await addVideo(content.id, {
                title: l.title,
                description: l.description,
                video_link: l.link,
              });
            } else if (l.questions) {
              let questions = l.questions?.map(q => {
                return {
                  question: q.title,
                  correct_answer: q.correctAnswer,
                  options: [q.answerOne, q.answerTwo, q.answerThree, q.answerFour],
                  multiple_choice: q.correctAnswer.length > 1,
                };
              });
              res = await addExam(content.id, {
                title: l.title,
                description: l.description,
                questions,
              });
            } else if (l.description) {
              res = await addArticle(content.id, {
                title: l.title,
                description: l.description,
              });
            }
          }
        }
      }
      navigate('/courses');
      addToasts({
        type: 'success',
        body: `Cource and Week content added successfully`,
      });
    } catch (err) {
      if (config.MODE === 'development') console.error(err);
      addToasts({
        type: 'danger',
        body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
      });
    }
  };

  // restore form data when navigating to add course content
  useEffect(() => {
    if (course) {
      setFormInputs(course);
      if (course.photo) {
        setFilePreview(URL.createObjectURL(course.photo));
      }
    }
    // eslint-disable-next-line
  }, []);

  const addSectionsHandler = () => {
    navigate('/courses/addsection', {
      state: {
        sections: sections || [],
        course: formInputs,
      },
    });
  };

  const editSectionTitle = (sectionTitle, sectionIndex) => {
    navigate('/courses/addsection', {
      state: {
        sections: sections || [],
        course: formInputs,
        sectionTitle: sectionTitle,
        sectionIndex: sectionIndex,
      },
    });
  };

  const addToSectionHandler = sectionIndex => {
    navigate('/courses/addlesson', {
      state: {
        sections: sections,
        index: sectionIndex,
        course: formInputs,
      },
    });
  };

  const deleteSectionHandler = async (sectionIndex, section) => {
    sections.splice(sectionIndex, 1);
    setSections([...sections]);
  };

  const editSectionContent = (sectionIndex, index, content) => {
    navigate('/courses/addlesson', {
      state: {
        sections: sections,
        sectionIndex: sectionIndex,
        contentData: content,
        contentIndex: index,
        contentType: content.link ? 'Video' : content.questions ? 'Exam' : 'Article',
      },
    });
  };

  const deleteSectionContent = async (sectionIndex, contentIndex, content) => {
    sections[sectionIndex].content.splice(contentIndex, 1);
    setSections([...sections]);
  };
  return {
    sections,
    filePreview,
    formInputs,
    signatureOnChangeHandler,
    signaturePreview,
    navigate,
    inputsOnChangeHandler,
    paymentTypeOnChangeHandler,
    professorOnChangeHandler,
    categoryOnChangeHandler,
    languageOnChangeHandler,
    levelOnChangeHandler,
    roleOnChangeHandler,
    fileOnChangeHandler,
    onClick,
    addSectionsHandler,
    editSectionTitle,
    addToSectionHandler,
    deleteSectionHandler,
    editSectionContent,
    deleteSectionContent,
    handleActiveChange,
  };
}

export default useAddCourse;
