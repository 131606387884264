import React from 'react';
import star from '../Assets/vector (2).png';
import { TiTickOutline } from 'react-icons/ti';
import PriceAndDeliveryCard from './_common/PriceAndDeliveryCard';
import ItemSellerCard from './_common/ItemSellerCard';

function StoreProductCard({ product, handleSelectProduct, className }) {
  return (
    <section
      key={product.id}
      id={product.id}
      className={'bg-white rounded-xl cursor-pointer box-content ' + className || ''}
      onClick={() => {
        handleSelectProduct(product.id);
      }}
    >
      <div className="w-full flex overflow-x-auto space-x-2 no-scrollbar justify-between">
        {product.images.map((el, index) => {
          return (
            <img
              key={index}
              id={product.id}
              src={el}
              alt="Product"
              className="max-h-64 p-0 m-0"
              crossOrigin="anonymous"
            />
          );
        })}
      </div>
      <div id={product.id} className="flex flex-col gap-2 p-2">
        <div className="flex items-center justify-between capitalize">
          <h1 id={product.id} className="font-semibold text-xl text-gray-800">
            {product.title}
          </h1>
          <span
            id={product.id}
            className={`${product.is_accepted === true ? 'text-green-500' : 'text-red-500'} text-xl font-semibold px-1`}
          >
            {product.is_accepted ? 'active' : 'not active'}
          </span>
        </div>
        <PriceAndDeliveryCard item={product} />
        <ItemSellerCard id={product.id} user={product.seller} />
        <div id={product.id} className="flex justify-start items-center divide-x-2 divide-gray-300 divide-dashed">
          <div id={product.id} className="flex items-center gap-1 pr-1">
            <img crossOrigin="anonymous" src={star} className="w-5" alt="Star" id={product.id} />
            <span className="font-semibold text-base" id={product.id}>
              {product.average_rating}
            </span>
            <span className="font-semibold text-base" id={product.id}>
              ({product.buyers_count})
            </span>
          </div>
          <span
            id={product.id}
            className={`${
              product.isUsed ? 'text-red-500' : 'text-green-500'
            } text-xl font-semibold flex items-center gap-1 ml-1 px-1`}
          >
            {product.isUsed ? 'Not' : <TiTickOutline className="text-2xl" />} New
          </span>
          <span
            id={product.id}
            className={`${
              product.status === 'Available'
                ? 'text-green-500'
                : product.status === 'Sold'
                ? 'text-red-500'
                : 'text-yellow-500'
            } text-xl font-semibold px-1`}
          >
            {product.status}
          </span>
        </div>
        {product.product_category.length > 0 && (
          <div className=" text-lg capitalize">
            categories :{' '}
            {product.product_category?.map((el, index) => {
              return (
                <span key={index} className="text-md mx-2 bg-slate-300 p-1 rounded-lg">
                  {el.name}
                </span>
              );
            })}
          </div>
        )}

        {product.product_tags.length > 0 && (
          <div className=" text-lg capitalize">
            Tags :{' '}
            {product.product_tags?.map((el, index) => {
              return (
                <div key={index} className="text-md m-1 p-1 rounded-lg">
                  <span className="p-1 rounded-lg">{el.name} :</span>
                  {el.options.map((option, index) => {
                    return (
                      <span key={index} className="p-1 mx-2 rounded-lg bg-slate-200">
                        {option}
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
        <span id={product.id} className="text-gray-600 font-semibold text-base">
          City: {product.city}
        </span>
        <span id={product.id} className="text-gray-600 font-semibold text-base">
          delivery time: {product.delivery_time}
        </span>
      </div>
    </section>
  );
}

export default StoreProductCard;
