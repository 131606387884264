import './Sidebar.css';
import logo from '../Assets/B.png';
import homeIcon from '../Assets/u_home-alt (1).svg';
import supportIcon from '../Assets/fi_headphones (1).svg';
import dashboardwalletIcon from '../Assets/u_wallet (1).svg';
import loansIcon from '../Assets/u_dollar-sign (1).svg';
import investingIcon from '../Assets/u_chart-line (1).svg';
import coursesIcon from '../Assets/u_play-circle.svg';
import storeIcon from '../Assets/u_shop (1).svg';
import mealsIcon from '../Assets/u_utensils-alt (1).svg';
import eventsIcon from '../Assets/u_calendar-alt (1).svg';
import findroommateIcon from '../Assets/u_users-alt.svg';
import universitiesIcon from '../Assets/u_university (1).svg';
import jobsIcon from '../Assets/u_suitcase-alt (1).svg';
import profilesIcon from '../Assets/u_user (1).svg';
import notificationIcon from '../Assets/Bell v2.4.svg';
import updateVersionIcon from '../Assets/updateVersionIcon.svg';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { GoPersonAdd } from 'react-icons/go';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import hubIcon from '../Assets/hub-icon.svg';
import reelsIcon from '../Assets/reels.svg';
export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div id="sidebar">
        <div className="bg-[#3F72AF] w-full flex items-center justify-center">
          <img src={logo} alt="Profile" width="50" height="50" />
        </div>

        <Link to="/" className={`sidebar-item ${location.pathname == '/' ? 'selected' : ''}`} title="Home">
          <img src={homeIcon} className={location.pathname == '/' ? 'icon-selected' : ''} alt="Home Icon" />
        </Link>
        <Link
          to="/create_account"
          className={`sidebar-item ${location.pathname.includes('create_account') ? 'selected' : ''}`}
          title="Create Account"
        >
          <GoPersonAdd
            className={
              location.pathname.includes('create_account')
                ? 'icon-selected text-[#116ACC] text-[28px]'
                : '' + ' text-[#6B7280] text-[28px]'
            }
            alt="Create Account"
          />
        </Link>
        <Link
          to={'/support'}
          className={`sidebar-item ${location.pathname.includes('support') ? 'selected' : ''}`}
          title="Support"
        >
          <img
            src={supportIcon}
            className={location.pathname.includes('supportChat') ? 'icon-selected' : ''}
            alt="supportIcon Icon"
          />
        </Link>
        <Link
          to={'/dashboardwallet'}
          className={`sidebar-item ${location.pathname.includes('dashboardwallet') ? 'selected' : ''}`}
        >
          <img
            src={dashboardwalletIcon}
            className={location.pathname.includes('dashboardwallet') ? 'icon-selected' : ''}
            alt="dashboardwalletIcon Icon"
          />
        </Link>
        <Link to={'/loans'} className={`sidebar-item ${location.pathname.includes('loan') ? 'selected' : ''}`}>
          <img src={loansIcon} className={location.pathname.includes('loan') ? 'icon-selected' : ''} alt="loans Icon" />
        </Link>
        <Link to={'/investing'} className={`sidebar-item ${location.pathname.includes('invest') ? 'selected' : ''}`}>
          <img
            src={investingIcon}
            className={location.pathname.includes('invest') ? 'icon-selected' : ''}
            alt="investing Icon"
          />
        </Link>
        <Link to={'/courses'} className={`sidebar-item ${location.pathname.includes('course') ? 'selected' : ''}`}>
          <img
            src={coursesIcon}
            className={location.pathname.includes('course') ? 'icon-selected' : ''}
            alt="courses Icon"
          />
        </Link>
        <Link to={'/store'} className={`sidebar-item ${location.pathname.includes('store') ? 'selected' : ''}`}>
          <img
            src={storeIcon}
            className={location.pathname.includes('store') ? 'icon-selected' : ''}
            alt="store Icon"
          />
        </Link>
        <Link to={'/meals'} className={`sidebar-item ${location.pathname.includes('meal') ? 'selected' : ''}`}>
          <img src={mealsIcon} className={location.pathname.includes('meal') ? 'icon-selected' : ''} alt="meals Icon" />
        </Link>
        <Link to={'/events'} className={`sidebar-item ${location.pathname.includes('event') ? 'selected' : ''}`}>
          <img
            src={eventsIcon}
            className={location.pathname.includes('event') ? 'icon-selected' : ''}
            alt="events Icon"
          />
        </Link>
        <Link to={'/roommate'} className={`sidebar-item ${location.pathname.includes('roommate') ? 'selected' : ''}`}>
          <img
            src={findroommateIcon}
            className={location.pathname.includes('roommate') ? 'icon-selected' : ''}
            alt="find_roommate Icon"
          />
        </Link>
        <Link
          to={'/universities'}
          className={`sidebar-item ${location.pathname.includes('universit') ? 'selected' : ''}`}
        >
          <img
            src={universitiesIcon}
            className={location.pathname.includes('universit') ? 'icon-selected' : ''}
            alt="universities Icon"
          />
        </Link>
        <Link to={'/jobs'} className={`sidebar-item ${location.pathname.includes('job') ? 'selected' : ''}`}>
          <img src={jobsIcon} className={location.pathname.includes('job') ? 'icon-selected' : ''} alt="jobs Icon" />
        </Link>
        <Link to={'/profiles'} className={`sidebar-item ${location.pathname.includes('profile') ? 'selected' : ''}`}>
          <img
            src={profilesIcon}
            className={location.pathname.includes('profile') ? 'icon-selected' : ''}
            alt="profiles Icon"
          />
        </Link>
        <Link
          to={'/notification'}
          className={`sidebar-item ${location.pathname.includes('notification') ? 'selected' : ''}`}
        >
          <img
            src={notificationIcon}
            className={location.pathname.includes('notification') ? 'icon-selected' : ''}
            alt="notification Icon"
          />
        </Link>
        <Link to={'/report'} className={`sidebar-item ${location.pathname.includes('report') ? 'selected' : ''}`}>
          <HiOutlineDocumentReport
            className={
              location.pathname.includes('report')
                ? 'icon-selected text-[#116ACC] text-3xl'
                : '' + ' text-[#6B7280] text-3xl'
            }
            alt="report Icon"
          />
        </Link>
        <Link
          to={'/update-version'}
          className={`sidebar-item ${location.pathname.includes('update-version') ? 'selected' : ''}`}
        >
          <img
            src={updateVersionIcon}
            className={location.pathname.includes('update-version') ? 'icon-selected' : ''}
            alt="update-version Icon"
          />
        </Link>
        <Link to={'/hub'} className={`sidebar-item ${location.pathname.includes('hub') ? 'selected' : ''}`}>
          <img src={hubIcon} className={location.pathname.includes('hub') ? 'icon-selected' : ''} alt="hub Icon" />
        </Link>
        <Link to={'/reels'} className={`sidebar-item ${location.pathname.includes('reels') ? 'selected' : ''}`}>
          <img src={reelsIcon} className={location.pathname.includes('reels') ? 'icon-selected' : ''} alt="hub Icon" />
        </Link>
      </div>
    </div>
  );
}
