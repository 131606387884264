import React from 'react';
import FullNameConcat from '../../Utils/FullNameConcat';
import convertDateToDateString from '../../Utils/convertDateToDateString';
export default function ProductOrderCard({ order, handleSelectOrder }) {
  return (
    <article key={order.id} id={order.id} className="storecard3">
      <div id={order.id} className="sc3firstline">
        <div id={order.id} className="sc3firstlineleft">
          <img
            crossOrigin="anonymous"
            id={order.id}
            src={order.user.avatar}
            className="w-12 h-12 rounded-full"
            alt={order.user.username}
          />
          <p id={order.id}>
            <span id={order.id} className="text-base text-gray-600">
              By
            </span>{' '}
            <span id={order.id} className="text-base text-gray-800 text-ellipsis">
              {FullNameConcat(order.user)}
            </span>
          </p>
        </div>
        <button id={order.id} className="sc3firstlineright" onClick={handleSelectOrder} style={{ cursor: 'pointer' }}>
          Details..
        </button>
      </div>
      <p id={order.id} className="sc3thirdline">
        {/* FIXME: */}
        {/* <span id={order.id} className="Moscow">
          {order.deliveryAddress.street}
        </span> */}
        <span id={order.id} className="datePassed">
          {convertDateToDateString(order.createdAt)}
        </span>
      </p>
      {order.products?.map(product => (
        <div className="sc3box" key={product.id}>
          {product.images && product.images.length > 0
            ? product.images?.map(image => (
                <img crossOrigin="anonymous" id={order.id} src={image} width={80} height={80} alt="" />
              ))
            : null}
          <div id={order.id} className="sc3boxleft">
            <p id={order.id}>{product.title}</p>
            <span id={order.id} className="storerub">
              {product.price} EGP
            </span>
            <hr></hr>
            <p id={order.id} className="underline">
              <span id={order.id}>
                {product.price} X {product.quantity}
              </span>
              <span id={order.id}>{product.price * product.quantity} EGP</span>
            </p>
          </div>
        </div>
      ))}
      <p className="text-base font-bold text-gray-800 text-ellipsis ">
        Total: <span className="text-base font-semibold text-blue-600">{order.total_price} EGP</span>
      </p>
    </article>
  );
}
