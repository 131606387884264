import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { updateUniversity } from '../APIs/UniversityAPIs';
import Select from 'react-select';
import uploadIcon from '../Assets/plus.png';
import '../Styles/AddUniversity.css';
import ToastContext from '../Context/ToastContext';
import Layout from '../Components/_common/Layout';
import Cities from '../Constants/Cities';

export default function EditUniversity() {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  console.log(data);
  const _id = data.id;
  const photoLink = data.photo.split('/');
  const [fileName, setFileName] = useState(photoLink[photoLink.length - 1]);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState('Public');
  const [city, setCity] = useState({
    value: data.city,
    label: data.city,
  });
  const [genre, setGenre] = useState(data.genre);
  const [name, setName] = useState(data.name);
  const [about, setAbout] = useState(data.about);
  const [founder, setFounder] = useState(data.founder);
  const [location, setLocation] = useState(data.location);
  const [link, setLink] = useState(data.link);
  const [graduates, setGraduates] = useState(data.graduates);
  const [professors, setProfessors] = useState(data.professors);
  const [colleges, setColleges] = useState(data.colleges);
  const { addToasts } = useContext(ToastContext);
  const isValid =
    fileName &&
    city &&
    genre?.trim() &&
    about?.trim() &&
    founder?.trim() &&
    link?.trim() &&
    name?.trim() &&
    graduates &&
    professors &&
    colleges &&
    location?.trim();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

 

  const handleFileChange = event => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0]?.name);
  };

  const handleStatusChange = event => {
    setSelectedStatus(event.target.value);
  };
  const handleGenreChange = event => {
    setGenre(event.target.value);
  };

  const onClick = async event => {
    event.preventDefault();
    try {
      let tmp = {
        city: city.value,
        genre,
        name,
        about,
        location,
        founder,
        link,
        graduates,
        professors,
        colleges,
        type: selectedStatus,
      };
      let res;
      if (file) {
        const formData = new FormData();
        formData.append('files', file);
        res = await upload(formData);
        const url = res.data?.fileLinks[0];
        if (!url) {
          addToasts({
            type: 'danger',
            body: 'Error while uploading file',
          });
          return;
        }
        tmp.photo = url;
      }
      res = await updateUniversity(_id, tmp);
      addToasts({
        body: 'university updated successfully',
        type: 'success',
      });
      navigate(-1);
    } catch (err) {
      console.error(err);
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  return (
    <Layout title={'Edit University'}>
      {!data ? (
        <h2 className="text-2xl my-10 w-full text-center">Something went wrong, Please try later</h2>
      ) : (
        <div className="my-5 bg-white rounded-2xl grid md:grid-cols-2 md:gap-5">
          <div className="p-5 flex flex-col gap-3">
            <label>Title of university</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Title of university"
            ></input>
            <label>About</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={about}
              onChange={e => setAbout(e.target.value)}
              placeholder="About"
            ></input>
            <label>Website link </label>
            <input
              className="border-2 p-2 rounded-xl"
              value={link}
              onChange={e => setLink(e.target.value)}
              placeholder="Website link"
            ></input>
            <label>University photo</label>
            <div className="photoUpload">
              <div className="uploadCustom">
                <span style={{ color: '#116ACC' }}>{fileName}</span>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={fileInputRef}
                  className="uploadInput"
                  onChange={handleFileChange}
                ></input>
              </div>
              <img src={uploadIcon} alt="icon" onClick={handleIconClick}></img>
            </div>
            <label>University location</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={location}
              onChange={e => setLocation(e.target.value)}
              placeholder="Location"
            ></input>
            <label>City</label>
            <Select
              options={Cities}
              isSearchable={true}
              onChange={setCity}
              value={city}
              placeholder="Select an option"
            />
            <label>University status</label>
            <div className="pageStatus">
              <label className={selectedStatus === 'Special' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="type"
                  value="Special"
                  checked={selectedStatus === 'Special'}
                  onChange={handleStatusChange}
                />
                Special
              </label>
              <label className={selectedStatus === 'Public' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="type"
                  value="Public"
                  checked={selectedStatus === 'Public'}
                  onChange={handleStatusChange}
                />
                Public
              </label>
            </div>
          </div>
          <div className="p-5 flex flex-col gap-3">
            <label>Founder</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={founder}
              onChange={e => setFounder(e.target.value)}
              placeholder="Founder"
            ></input>
            <label>Genre or type of university</label>
            <div className="flex gap-3 items-center">
              <label className={genre === 'Private' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="genre"
                  value="Private"
                  checked={genre === 'Private'}
                  onChange={handleGenreChange}
                />
                Private
              </label>
              <label className={genre === 'Public' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="genre"
                  value="Public"
                  checked={genre === 'Public'}
                  onChange={handleGenreChange}
                />
                Public
              </label>
            </div>
            <label>Colleges</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={colleges}
              onChange={e => setColleges(e.target.value)}
              type="number"
              placeholder="Colleges"
            ></input>
            <label>Professors</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={professors}
              onChange={e => setProfessors(e.target.value)}
              type="number"
              placeholder="Professors"
            ></input>
            <label>Graduate students</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={graduates}
              onChange={e => setGraduates(e.target.value)}
              type="number"
              placeholder="Graduate students"
            ></input>
            <button disabled={!isValid} onClick={onClick} className="addButton">
              Update
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
}
