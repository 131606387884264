import { useContext, useEffect, useState } from 'react';
import ToastContext from '../Context/ToastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrderById, updateMealOrderStatusById } from '../APIs/OrderAPIs';

const OrderHook = () => {
  const { addToasts } = useContext(ToastContext);
  const {
    state: { id },
  } = useLocation();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const getOrderData = async () => {
    setLoading(true);
    getOrderById(id)
      .then(res => {
        if (res.status === 200) {
          const { orders } = res.data.data;
          setOrder(orders[0]);
          setStatus(orders[0].status);
        }
      })
      .catch(err => {
        console.error(err);
        addToasts({
          body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
          type: 'danger',
        });
      })
      .finally(() => setLoading(false));
  };

  const updateOrderStatus = async status => {
    setLoading(true);
    updateMealOrderStatusById(id, { status })
      .then(res => {
        if (res.status === 200) {
          addToasts({
            type: 'success',
            body: 'meal order updated successfully',
          });
          navigate(-1);
        }
      })
      .catch(err => {
        console.error(err);
        addToasts({
          body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
          type: 'danger',
        });
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getOrderData();
    // eslint-disable-next-line
  }, []);

  return { order, loading, updateOrderStatus, status, setStatus };
};

export default OrderHook;
