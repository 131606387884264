import Sidebar from '../Components/Sidebar';
import '../Styles/OrdersToDo.css';
import arrowFrame from '../Assets/arrowframe.png';
import '../Components/Searchbar.css';
import LoadingSpinner from '../Components/LoadingSpinner';
import OrderCard from '../Components/Pages/OrdersToDoPage/OrderCard/OrderCard.js';
import OrdersToDoHook from '../Hooks/OrdersToDoHook.js';
import Layout from '../Components/_common/Layout.jsx';

export default function OrdersToDo() {
  const [
    input,
    chefStatus,
    loading,
    data,
    searchData,
    handleInputChange,
    ordersStatusNavigationSelectHandler,
    handleSelectOrder,
  ] = OrdersToDoHook();

  return (
    <Layout
      title={'Meals Orders'}
      Action={<input placeholder="Search" className="border" value={input} onChange={handleInputChange}></input>}
    >
      <div className=" bg-white rounded-lg p-5 text-2xl space-y-1  my-3 flex items-center flex-wrap capitalize">
        <span
          className={`${chefStatus === 'pending' ? 'selected' : ''} cursor-pointer mr-3`}
          id="pending"
          onClick={ordersStatusNavigationSelectHandler}
        >
          Not accepted
        </span>
        <span
          className={`${chefStatus === 'preparing' ? 'selected' : ''} cursor-pointer mr-3`}
          id="preparing"
          onClick={ordersStatusNavigationSelectHandler}
        >
          Doing
        </span>
        <span
          className={`${chefStatus === 'readyToDeliver' ? 'selected' : ''} cursor-pointer mr-3`}
          id="readyToDeliver"
          onClick={ordersStatusNavigationSelectHandler}
        >
          readyToDeliver
        </span>
        <span
          className={`${chefStatus === 'delivering' ? 'selected' : ''} cursor-pointer mr-3`}
          id="delivering"
          onClick={ordersStatusNavigationSelectHandler}
        >
          delivering
        </span>
        <span
          className={`${chefStatus === 'delivered' ? 'selected' : ''} cursor-pointer mr-3`}
          id="delivered"
          onClick={ordersStatusNavigationSelectHandler}
        >
          delivered
        </span>
        <span
          className={`${chefStatus === 'rejected' ? 'selected' : ''} cursor-pointer mr-3`}
          id="rejected"
          onClick={ordersStatusNavigationSelectHandler}
        >
          rejected
        </span>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : !searchData.length && !data.length ? (
        <h2 className="text-2xl my-10 w-full text-center">No Orders Found</h2>
      ) : (
        <div className="my-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {input
            ? searchData?.map(order => <OrderCard key={order.id} order={order} handleSelectOrder={handleSelectOrder} />)
            : data?.map(order => <OrderCard key={order.id} order={order} handleSelectOrder={handleSelectOrder} />)}
        </div>
      )}
    </Layout>
  );
}
