import '../Styles/Order.css';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import LoadingSpinner from '../Components/LoadingSpinner';
import convertDateToDateString from '../Utils/convertDateToDateString';
import convertTimeDecimalToTimeFormat from '../Utils/convertTimeDecimalToTimeFormat';
import { FaLocationDot } from 'react-icons/fa6';
import OrderHook from '../Hooks/OrderHook';

export default function Order() {
  const { order, loading, updateOrderStatus, status, setStatus } = OrderHook();

  return (
    <>
      <Sidebar />
      <TopCard title="Order" />
      {loading ? (
        <LoadingSpinner />
      ) : (
        order && (
          <div>
            {order.user && (
              <div className="orderContent">
                <div className="order-card">
                  <div className="from-to">
                    <div>
                      <img
                        src={order.user.avatar}
                        className="w-12 h-12 rounded-full"
                        crossOrigin="anonymous"
                        width={100}
                        alt="Sender Avatar"
                      />
                      <div>
                        <div>From (User)</div>
                        <div className="bold">{order.user.first_name}</div>
                      </div>
                    </div>
                    <div>
                      <img
                        src={order.chef.avatar}
                        className="w-12 h-12 rounded-full"
                        crossOrigin="anonymous"
                        width={100}
                        alt="Chef Avatar"
                      />
                      <div>
                        <div>To (chef)</div>
                        <div className="bold">{order.chef.first_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="my-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="bold primary">{order.receive_method}</div>
                    <div className="text-dark_gray">{convertDateToDateString(order.createdAt)}</div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="text-dark_gray">
                      Total: <span className="black">{order.total_price} EGP</span>
                    </div>
                    <div className="text-dark_gray">
                      Chef will take from this order: <span className="black">{order.seller_gain} EGP</span>
                    </div>
                  </div>
                  <div className="text-dark_gray">
                    Time to prepare order:
                    <span className="text-blue-500"> {convertTimeDecimalToTimeFormat(order.time_to_prepare)}</span>
                  </div>
                  <div className="text-dark_gray">
                    Time to deliver order:
                    <span className="text-blue-500"> {convertTimeDecimalToTimeFormat(order.time_to_deliver)}</span>
                  </div>
                  <div className="text-dark_gray">
                    Notes from customer: <span className="black"> {order.note}</span>.
                  </div>
                  <div className="text-dark_gray">
                    Customer phone number: <span className="black">{order.user.phone_number}</span>
                  </div>
                  <div className="text-dark_gray">
                    Meal will be received after:
                    <span className="text-blue-500">
                      {convertTimeDecimalToTimeFormat(order.time_to_prepare + order.time_to_deliver)}
                    </span>
                  </div>
                  <div className="flex flex-row gap-2 mt-2">
                    <span className="text-blue-500 mt-1">
                      <FaLocationDot />
                    </span>
                    <ul className="text-sm text-gray-600">
                      <li className="font-bold">
                        {order.deliveryAddress.address} - {order.deliveryAddress.street},
                      </li>
                      <li>
                        Building: <span className="font-bold">{order.deliveryAddress.building}</span>,
                      </li>
                      <li>
                        Floor: <span className="font-bold">{order.deliveryAddress.floor}</span>,
                      </li>
                      <li>
                        Flat: <span className="font-bold">{order.deliveryAddress.flat}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="p-5 my-5 bg-white rounded-xl h-fit">
                  {order.status !== 'delivered' ? (
                    <>
                      <h2 className="text-2xl font-semibold">Order tracking</h2>
                      <h3 className="text-xl font-medium my-3 capitalize">Current Status: {order.status}</h3>
                      <div className="space-x-2 my-3 flex flex-wrap">
                        <span
                          className={`${
                            status === 'delivering' ? 'bg-[#116acc]' : 'bg-gray-400'
                          } cursor-pointer p-2 text-lg rounded-xl text-white flex-shrink-0`}
                          onClick={() => setStatus('delivering')}
                        >
                          Delivering
                        </span>
                        <span
                          className={`${
                            status === 'delivered' ? 'bg-[#116acc]' : 'bg-gray-400'
                          } cursor-pointer p-2 text-lg rounded-xl text-white flex-shrink-0`}
                          onClick={() => setStatus('delivered')}
                        >
                          Delivered
                        </span>
                      </div>
                      <button
                        disabled={loading}
                        onClick={() => {
                          updateOrderStatus(status);
                        }}
                        className={`${
                          loading ? 'bg-[#9ca3af]' : 'bg-[#116acc]'
                        } cursor-pointer rounded-xl text-xl mx-auto w-1/2 block text-center mt-5 p-3 text-white`}
                      >
                        Update
                      </button>
                    </>
                  ) : (
                    <div className="p-5 bg-white rounded-xl">
                      <h2 className="text-2xl font-semibold">Track order</h2>
                      <span className="text-center block font-semibold text-xl my-2 text-gray-500">
                        Meal Order was done
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      )}
    </>
  );
}
