import api from './_common/helper';

export async function getReports(content, page) {
  if (content === 'post') return api.get(`/postReport/notReviewed?skip=${page * 20}`);
  else if (content === 'comment') return api.get(`/commentReport?skip=${page * 20}`);
  else if (content === 'reel') return api.get(`/reel-reports/unreviewed?skip=${page * 20}`);
  else if (content === 'story') return api.get(`/storyReport/unreviewed?skip=${page * 20}`);
  else if (content === 'user') return api.get(`/user-report/notReviewed?skip=${page * 20}`);
}

export async function updateReport(content, id, action) {
  if (content === 'post') return api.put(`/postReport/${id}`, { action });
  else if (content === 'comment') {
    const isAccepted = action === 'delete' ? true : false;
    return api.patch(`/commentReport/${id}`, { isReviewed: true, isAccepted });
  } else if (content === 'story') return api.patch(`/storyReport/${id}`, { action });
  else if (content === 'reel') {
    const isAccepted = action === 'delete' ? true : false;
    return api.patch(`/reel-reports/${id}`, { isAccepted });
  } else if (content === 'user') {
    return api.patch(`/user-report/${id}`, { action });
  }
}
