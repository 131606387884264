import api from './_common/helper';

export async function searchJobs(data, page) {
  return api.get(`/jobs/search/${data}?skip=${page * 20}&dashboard=true`);
}

export async function getJobs(page) {
  return api.get(`/jobs?skip=${page * 20}&dashboard=true`);
}

export async function getJob(data) {
  return api.get(`/jobs/${data}?dashboard=true`);
}

export async function getJobRequests(id) {
  return api.get(`/job-requests/job/${id}`);
}

export async function updateJob(id, data) {
  return api.put(`/jobs/${id}`, data);
}

export async function addJob(data) {
  return api.post(`/jobs`, data);
}

export async function deleteJob(id) {
  return api.delete(`/jobs/${id}`);
}
