import { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteUniversity, getUniversity, getUniversityStudents } from '../APIs/UniversityAPIs';
import ToastContext from '../Context/ToastContext';

function useUniversity(universityId) {
  const lastStudentRef = useRef(null);
  const [data, setData] = useState({});
  const [students, setStudents] = useState([]);
  const [maxStudents, setMaxStudents] = useState(0);
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          // Fetch more students when the last student is visible
          const skip = students.length;
          if (skip < maxStudents) {
            fetchStudents(universityId, skip).then(data => {
              const newStudents = data.students;
              const newStudentsCount = data.studentsCount;
              setStudents(prevStudents => [...prevStudents, ...newStudents]);
              setMaxStudents(newStudentsCount);
            });
          }
        }
      },
      { threshold: 0.5 }, // Call the callback when the last student is fully visible
    );

    if (lastStudentRef.current) {
      observer.observe(lastStudentRef.current);
    }

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, [students]);
  const fetchStudents = async (skip = 0) => {
    try {
      const students = await getUniversityStudents(universityId, skip);
      if (students.data.success === false) {
        addToasts({
          type: 'danger',
          body: 'University not found',
        });
      }
      return students.data.data;
    } catch (err) {
      addToasts({
        type: 'danger',
        body: err.response.data.msg || 'Unexpected error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const university = await getUniversity(universityId);
        const students = await fetchStudents();
        setStudents(students.students);
        setMaxStudents(students.studentsCount);
        if (university.data.success === false) {
          addToasts({
            type: 'danger',
            body: 'University not found',
          });
        }
        const data = university.data.data;
        setData(data.university);
        setLoading(false);
      } catch (err) {
        addToasts({
          type: 'danger',
          body: 'unexpected error',
        });
      }
    })();
  }, []);

  const onDelete = async () => {
    try {
      const res = await deleteUniversity(universityId);
      if (res.status === 200) {
        addToasts({
          body: 'University deleted successfully',
          type: 'success',
        });
      }
    } catch (err) {
      addToasts({
        body: 'Error deleting university',
        type: 'error',
      });
    }
    navigate('/universities');
  };
  return { data, students, loading, lastStudentRef, navigate, onDelete };
}

export default useUniversity;
