import { useContext, useEffect, useState } from 'react';
import { saveSession } from '../Utils/SessionUtils';
import logo from '../Assets/B.png';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Signin.css';
import UserContext from '../Context/UserContext';
import ToastContext from '../Context/ToastContext';
import { loginReq } from '../APIs/AuthAPIs';

import '../App.css';
export default function Signin() {
  const [email, setEmail] = useState('');
  const [animateClass, setAnimateClass] = useState('animate');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [accept, setAccept] = useState(false);
  const { setUserData } = useContext(UserContext);
  const { addToasts } = useContext(ToastContext);

  const isValid = email.trim() && password.trim();

  useEffect(() => {
    setTimeout(() => setAnimateClass(''), 1000);
  }, []);
  const onSubmit = async event => {
    event.preventDefault();

    try {
      let res = await loginReq({
        email: email.trim().toLocaleLowerCase(),
        password: password.trim(),
      });

      const jsonData = res.data.data;
      if (jsonData.user.role !== 'ADMIN') {
        addToasts({
          body: 'You are not authorized to access this page',
          type: 'danger',
        });
        return;
      }
      addToasts({
        body: 'Welcome to Rafiqy Dashboard!',
        type: 'success',
      });

      // save the token to the session
      saveSession(jsonData.token);

      // update the user data in the context
      setUserData(jsonData.user);

      // redirect to home
      navigate('/');
    } catch (err) {
      if (err.response.status === 401) {
        addToasts({
          body: 'Invalid Email or Password',
          type: 'danger',
        });
        return;
      }
      console.error(err);
      addToasts({
        body: 'Something went wrong, try again later',
        type: 'danger',
      });
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <div id="signin">
        <form onSubmit={onSubmit} id="form">
          <img className="w-32 my-5" src={logo} id="logosignin" alt="loginIcon" />
          <h1 id="raf2">
            Sign in at <p className="raf">Rafiqy</p> dashboard
          </h1>
          <label id="label1" htmlFor="email">
            Email address
          </label>
          <input
            type="email"
            id="email"
            placeholder="Write the email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <label id="label2" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Write the password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          {accept && password === '' ? <p id="error">Something went wrong try again</p> : null}

          <button disabled={!isValid} id="submit" type="submit">
            Sign in
          </button>
        </form>
      </div>
    </>
  );
}
