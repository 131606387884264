import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { updateEvent } from '../APIs/EventAPIs';
import uploadIcon from '../Assets/plus.png';
import '../Styles/EditEvent.css';
import ToastContext from '../Context/ToastContext';
import DatePicker from 'react-multi-date-picker';
import Layout from '../Components/_common/Layout';

export default function EditEvent() {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  console.log(data);
  const id = data.id;
  const initialLinks = data.photo || [];
  const [fileNames, setFileNames] = useState(initialLinks.map(url => url.split('/').pop()));
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [type, setType] = useState(data.type);
  const [title, setTitle] = useState(data.title);
  const [active, setActive] = useState(data.active);
  const [date, setDate] = useState(data.date);
  const [details, setDetails] = useState(data.details);
  const [location, setLocation] = useState(data.location);
  const { addToasts } = useContext(ToastContext);
  const isValid = type.trim() && fileNames.length > 0 && title.trim() && details.trim() && location.trim();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleActiveChange = event => {
    console.log(event.target.checked);
    setActive(event.target.checked);
  };

  const handleFileChange = event => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    setFileNames(prevFiles => [...prevFiles, ...selectedFiles.map(file => file.name)]);
    console.log(
      'Files selected:',
      selectedFiles.map(file => file.name),
    );
  };

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const onClick = async event => {
    event.preventDefault();
    try {
      let tmp = {
        title,
        details,
        location,
        date,
        type,
        active,
      };

      if (files.length > 0) {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));
        let res = await upload(formData);
        if (res.status !== 200) {
          addToasts({
            type: 'danger',
            body: 'network error',
          });
          return;
        }
        tmp.photo = res.data.fileLinks;
      } else {
        tmp.photo = initialLinks;
      }

      await updateEvent(id, tmp);
      addToasts({
        body: 'event updated successfully',
        type: 'success',
      });

      navigate(-1);
    } catch (err) {
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  return (
    <Layout title={'Edit Event'}>
      <div className="w-full md:w-1/2 my-6 mx-auto bg-white rounded-2xl p-5 flex flex-col space-y-3">
        <label>Type of event</label>
        <div className="typeOfEvent">
          <label className={type === 'event' ? 'radio-label selected' : 'radio-label'}>
            <input type="radio" name="options" value="event" checked={type === 'event'} onChange={handleTypeChange} />
            Event
          </label>
          <label className={type === 'conference' ? 'radio-label selected' : 'radio-label'}>
            <input
              type="radio"
              name="options"
              value="conference"
              checked={type === 'conference'}
              onChange={handleTypeChange}
            />
            Conference
          </label>
          <label className={type === 'party' ? 'radio-label selected' : 'radio-label'}>
            <input type="radio" name="options" value="party" checked={type === 'party'} onChange={handleTypeChange} />
            Party
          </label>
        </div>
        <label>Title of Event</label>
        <input id="eventTitle" placeholder="Title of Event" value={title} onChange={e => setTitle(e.target.value)} />
        <label>Details</label>
        <textarea id="eventDetails" placeholder="Details" value={details} onChange={e => setDetails(e.target.value)} />
        <label>Event photo(s)</label>
        <div className="photoUpload">
          <div className="uploadCustom">
            <span style={{ color: '#116ACC' }}>{fileNames.join(', ')}</span>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={fileInputRef}
              className="uploadInput"
              placeholder="Photos"
              multiple
              onChange={handleFileChange}
            />
          </div>
          <img src={uploadIcon} alt="icon" onClick={handleIconClick} />
        </div>
        <label>Event location</label>
        <input
          id="eventLocation"
          placeholder="Location of Event"
          value={location}
          onChange={e => setLocation(e.target.value)}
        />
        <div>
          <label htmlFor="active">Active</label>
          <input className="ml-2" id="active" type="checkbox" checked={active} onChange={handleActiveChange} />
        </div>
        <label>Select date</label>
        <DatePicker onChange={setDate} value={date} />
        <button
          className="disabled:bg-gray-400 w-1/3 bg-blue-500 p-2 my-2 text-xl mx-auto rounded-md cursor-pointer text-white"
          disabled={!isValid}
          onClick={onClick}
        >
          Edit
        </button>
      </div>
    </Layout>
  );
}
