import vector1 from '../../../Assets/vector (1).png';
import dollar from '../../../Assets/u_dollar-sign-alt.png';
import clock from '../../../Assets/u_clock-three.png';
import star from '../../../Assets/vector (2).png';
import friend from '../../../Assets/vector (3).png';
import blankDefaultImage from '../../../Assets/blankDefaultImage.png';

const CourseCard = ({ course, handleSelectCourse, className }) => {
  return (
    <div
      key={course.id}
      id={course.id}
      className={'coursesCard box-content overflow-hidden ' + className || ''}
      onClick={handleSelectCourse}
      style={{ cursor: 'pointer' }}
    >
      <img
        id={course.id}
        crossOrigin="anonymous"
        src={course.photo || blankDefaultImage}
        alt={course.title}
        className="courseLogo"
      ></img>
      <div id={course.id} className="courseInfo">
        <div id={course.id} className="w-full flex items-center justify-between text-lg font-semibold my-2">
          <span className="text-2xl" id={course.id}>
            {course.title}
          </span>

          <span className={course.active === true ? 'text-green-600' : 'text-red-600'} id={course.id}>
            {course.active ? 'Active' : 'Not Active'}
          </span>
        </div>
        <p>
          {course.discount > 0 && (
            <span className="coursePrice mr-2">{course.price - (course.price * course.discount) / 100 + ' EGP'}</span>
          )}
          <span className={`coursePrice ${course.discount > 0 ? 'line-through text-gray-500' : ''}`}>
            {course.isFree ? ' Free' : `${course.price} EGP`}
          </span>
        </p>

        <div id={course.id} className="courseType">
          <span id={course.id}>
            <img id={course.id} src={dollar} alt="dollar icon" className="icon"></img>
            <span id={course.id}> {course.price * course.students_count} EGP</span>
          </span>
          <span id={course.id}>
            <img id={course.id} src={vector1} alt="category" className="icon"></img>
            <span id={course.id}>{course.category}</span>
          </span>
        </div>
        <div id={course.id} className="courseRates">
          <span id={course.id}>
            <img id={course.id} src={clock} alt="clock" className="icon"></img>
            <span id={course.id}> {course.time}H</span>
          </span>
          <span id={course.id}>
            <img id={course.id} src={star} alt="star" className="icon"></img>
            <span id={course.id}>
              {course.average_rating}
              {/* // ! deprecated */}
              {/* ({course.reviews.length}) */}
            </span>
          </span>
          <span id={course.id}>
            <img id={course.id} src={friend} alt="count" className="icon"></img>
            <span id={course.id}>{course.students_count}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
