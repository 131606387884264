import React from 'react';
import FullNameConcat from '../../Utils/FullNameConcat.js';
function VerificationRequestCard({ data, handleProfileClick }) {
  return (
    <div
      key={data.id}
      id={data.id}
      onClick={handleProfileClick}
      style={{ cursor: 'pointer' }}
      className="bg-white flex justify-between items-center p-3 rounded-2xl mt-3 w-fit gap-2"
    >
      <div id={data.id} className="flex items-center gap-2">
        <img
          crossOrigin="anonymous"
          id={data.id}
          src={data.user.avatar}
          alt="Avatar"
          className="rounded-full w-16 h-16"
        ></img>
        <p id={data.id} className="flex flex-col gap-1">
          <span id={data.id} className="font-bold">
            {FullNameConcat(data.user)}
          </span>
          <span id={data.id} className="text-gray-400">
            {data.user.username}
          </span>
        </p>
      </div>
      <button id={data.id} className="bg-blue-500 text-white px-4 py-2 rounded-lg" onClick={handleProfileClick}>
        View Request
      </button>
    </div>
  );
}

export default VerificationRequestCard;
