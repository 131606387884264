import api from './_common/helper';
const EXAM_PREFIX = '/course/exam';
const ARTICLE_PREFIX = '/course/article';
const VIDEO_PREFIX = '/course/video';

export async function searchCourses(data) {
  let query = `/course/search?text=${data.text}`;
  if (data.category) query += `&category=${data.category}`;
  if (data.is_free !== undefined || data.is_free !== null) query += `&is_free=${data.is_free}`;
  query += `&skip=${data.skip}&dashboard=true`;
  return api.get(query);
}

export async function getCourses(data) {
  return api.get(`/course?skip=${data.skip}&filter=${data.filter}&dashboard=true`);
}

export async function getCourse(courseId) {
  return api.get(`/course/${courseId}?dashboard=true`);
}

export async function getCourseContent(courseId) {
  return api.get(`/content/course/${courseId}`);
}

export async function getProffessors() {
  return api.get(`/professors`);
}

export async function addCourse(data) {
  return api.post(`/course`, data);
}

export async function addArticle(contentId, data) {
  return api.post(`/course/weekContent/${contentId}/article`, data);
}

export async function addExam(contentId, data) {
  return api.post(`/course/weekContent/${contentId}/exam`, data);
}

export async function addVideo(contentId, data) {
  return api.post(`/course/weekContent/${contentId}/video`, data);
}

export async function getExamQuestions(examId) {
  return api.get(`/course/exam/${examId}/questions`);
}

export async function addWeekContent(data) {
  return api.post(`/course/weekContent`, data);
}

export async function updateWeekContent(content_id, data) {
  return api.put(`/content/week/${content_id}`, data);
}

export async function updateArticle(article_id, data) {
  return api.put(`${ARTICLE_PREFIX}/${article_id}`, data);
}

export async function deleteArticle(article_id) {
  return api.delete(`${ARTICLE_PREFIX}/${article_id}`);
}

export async function updateVideo(video_id, data) {
  return api.put(`${VIDEO_PREFIX}/${video_id}`, data);
}

export async function deleteVideo(video_id) {
  return api.delete(`${VIDEO_PREFIX}/${video_id}`);
}

export async function getExam(exam_id) {
  return api.get(`/content/exam/${exam_id}`);
}

export async function getExamQuestionsAndAnswers(exam_id) {
  return api.get(`${EXAM_PREFIX}/questions-answers/${exam_id}`);
}

export async function getSingleQuestion(question_id) {
  return api.get(`${EXAM_PREFIX}/question/${question_id}`);
}

export async function createSingleQuestion(data) {
  return api.post(`${EXAM_PREFIX}/question`, data);
}

export async function updateSingleQuestion(question_id, data) {
  return api.put(`${EXAM_PREFIX}/question/${question_id}`, data);
}

export async function deleteSingleQuestion(question_id) {
  return api.delete(`${EXAM_PREFIX}/question/${question_id}`);
}

export async function updateExam(exam_id, data) {
  return api.put(`${EXAM_PREFIX}/${exam_id}`, data);
}

export async function deleteExam(exam_id) {
  return api.delete(`${EXAM_PREFIX}/${exam_id}`);
}

export async function getWeekContent(content_id) {
  return api.get(`/content/week/${content_id}`);
}

export async function deleteWeekContent(content_id) {
  return api.delete(`/content/week/${content_id}`);
}

export async function updateCourse(id, data) {
  return api.put(`/course/${id}`, data);
}

export async function deleteCourse(courseId) {
  return api.delete(`/course/${courseId}`);
}
