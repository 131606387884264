import convertDateToDateString from '../../../../Utils/convertDateToDateString';

const OrderCard = ({ order, handleSelectOrder }) => {
  return (
    <div
      id={order.id}
      onClick={() => handleSelectOrder(order.id)}
      style={{ cursor: 'pointer' }}
      className="ordersToDoCard"
    >
      {/* user */}
      <div id={order.id} className="sc3firstline">
        <div id={order.id} className="sc3firstlineleft">
          <img
            crossOrigin="anonymous"
            id={order.id}
            src={order.user?.avatar}
            className="w-12 h-12 rounded-full"
            alt={order.user.first_name}
          ></img>
          <div id={order.id}>
            <p id={order.id} className="from">
              From (User)
            </p>
            <span className="font-bold" id={order.id}>
              {order.user.first_name}
            </span>
          </div>
        </div>

        {/* chef */}
        <div id={order.id} className="sc3firstlineleft">
          <img
            src={order.user?.avatar}
            className="w-12 h-12 rounded-full"
            crossOrigin="anonymous"
            alt={order.chef.first_name}
          ></img>
          <div id={order.id}>
            <p id={order.id} className="from">
              To (chef)
            </p>
            <span className="font-bold" id={order.id}>
              {order.user.first_name}
            </span>
          </div>
        </div>
      </div>
      <div id={order.id} className="sc3thirdline mt-3 mb-2">
        <p id={order.id} className="Moscow">
          {order.deliveryAddress.address}
        </p>
        <span id={order.id} className="text-dark_gray">
          {convertDateToDateString(order.createdAt)}
        </span>
      </div>
      {order.meals.length &&
        order.meals?.map(meal => (
          <div key={meal.id} className="sc3box2">
            <img src={meal.image} width={80} height={80} crossOrigin="anonymous" alt={meal.title}></img>
            <div className="sc3boxleft">
              <p>{meal.title}</p>
              <span className="font-medium">{meal.price} EGP</span>
              <div className="underline my-2">
                <span>
                  {meal.price} X {meal.quantity}
                </span>
                <div>{meal.price * meal.quantity} EGP</div>
              </div>
            </div>
          </div>
        ))}
      <div className="flex items-center justify-between my-2 font-bold">
        <div>Total:</div>
        <div>{order.total_price} EGP</div>
      </div>
    </div>
  );
};

export default OrderCard;
