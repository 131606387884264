import api from './_common/helper';

export async function getAllMealsByStatus(data) {
  return api.get(`/order/status?status=${data.chefStatus}&skip=${data.skip}`);
}

export async function searchOrders(data) {
  return api.get(`/order/search?query=${data.text}&status=${data.chefStatus}&skip=${data.skip}`);
}

export async function getOrderById(orderId) {
  return api.get(`/order/${orderId}`);
}

export async function updateMealOrderStatusById(orderId, data) {
  return api.patch(`/order/${orderId}/status`, data);
}

export async function getUpdate() {
  return api.get('/updates');
}

export async function getProductOrderById(orderId) {
  return api.get(`/product/order/${orderId}`);
}

export async function updateProductOrder(id, data) {
  return api.patch(`/product/order/${id}/status`, data);
}
