import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/VerificationRequest.css';
import BasicModalTwo from '../Components/ModalTwo';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVRequest, updateStatus } from '../APIs/VRequestAPIs';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import FullNameConcat from '../Utils/FullNameConcat';
import Layout from '../Components/_common/Layout';
import convertDateToDateString from '../Utils/convertDateToDateString';

export default function VerificationRequest() {
  const {
    state: { id },
  } = useLocation();
  const [data, setData] = useState({});
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const vrequest = await getVRequest(id);
        if (vrequest.status !== 200) {
          addToasts({
            type: 'danger',
            body: 'network error',
          });
        }
        console.log(vrequest.data.data);
        setData(vrequest.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        addToasts({
          type: 'danger',
          heading: 'Unexpected error',
          body: err?.response?.data?.msg,
        });
      }
    })();
  }, []);

  const onClick = async status => {
    try {
      const res = await updateStatus(status, id);
      console.log(res.status);
      addToasts({
        type: 'success',
        body: 'Request updated successfully',
      });
    } catch (error) {
      console.error('Error updating request:', error);
      addToasts({
        type: 'danger',
        heading: 'Unexpected error',
        body: error?.response?.data?.msg || 'An unexpected error occurred',
      });
    } finally {
      navigate('/profiles/verificationrequests');
    }
  };

  return (
    <Layout title={'Verification Request'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-full flex mt-4 justify-center">
          <div className="flex flex-wrap items-stretch justify-center min-h-full w-fit divide-x-2 bg-white shadow rounded-lg overflow-hidden">
            <VerificationRequestSection title="User Information">
              <div className="flex items-center gap-2">
                <img
                  crossOrigin="anonymous"
                  width={100}
                  src={data?.user.avatar}
                  alt="Avatar"
                  className="rounded-full"
                ></img>
                <p className="flex flex-col gap-1">
                  <span className="text-black font-bold">{FullNameConcat(data?.user)}</span>
                  <span className="text-gray-400">{data?.user.username}</span>
                </p>
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <p>
                  Email: <span className="text-black font-bold">{data?.user.email}</span>
                </p>
                <p>
                  Phone: <span className="text-black font-bold">{data?.user.phone_number}</span>
                </p>
                <p>
                  Created at: <span className="text-black font-bold">{convertDateToDateString(data?.createdAt)}</span>
                </p>
              </div>
              <p>
                Current status: <span className="text-black font-bold">{data?.status.toUpperCase()}</span>
              </p>
            </VerificationRequestSection>
            <VerificationRequestSection title="Documents">
              <div className="flex flex-nowrap items-center overflow-x-auto w-full gap-2 bg-gray-500">
                <img
                  crossOrigin="anonymous"
                  src={data?.document_back_image}
                  alt="document_back_image"
                  className="object-contain h-full w-full rounded"
                />
                <img
                  crossOrigin="anonymous"
                  src={data?.document_front_image}
                  alt="document_front_image"
                  className="object-contain h-full w-full rounded"
                />
                <img
                  crossOrigin="anonymous"
                  src={data?.document_user_image}
                  alt="document_user_image"
                  className="object-contain h-full w-full rounded"
                />
              </div>
            </VerificationRequestSection>
            <VerificationRequestSection title="Actions">
              <BasicModalTwo
                question="Are you sure you want to accept this request?"
                buttonName="Accept"
                onAccept={() => onClick('approved')}
                bgColor={'#00FF00'}
              ></BasicModalTwo>
              <BasicModalTwo
                question="Are you sure you want to reject this request?"
                buttonName="Reject"
                onAccept={() => onClick('rejected')}
                bgColor={'#FF0000'}
              ></BasicModalTwo>
            </VerificationRequestSection>
          </div>
        </div>
      )}
    </Layout>
  );
}

function VerificationRequestSection({ title, children, className = '' }) {
  return (
    <div className={`w-full sm:w-1/2 md:w-1/4 min-w-56 flex flex-col bg-white p-6 ${className}`}>
      <h1 className="text-2xl font-bold mb-4">{title}</h1>
      {children}
    </div>
  );
}
